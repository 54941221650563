import React, { useEffect, useState } from 'react';
import Spinner from '../Spinner';
import { getSearchExpert } from '../../commons/apis/services/expert.js';

export default function TopExperts() {
  const [currentPage, setCurrentPage] = useState(0);
  const [filterMode, setFilterMode] = useState(false);
  const [pageSize,setPageSize] = useState(3);
  const [pageNumber, setPageNumber] = useState(5);
  const [randomNumber, setRandomNumber] = useState(Math.random());
  const [sortBy, setSortBy] = useState('citations');
  const topK = pageSize * pageNumber;
  const [loadingExperts, setLoadingExperts] = useState(false);
  const [dataExperts, setDataTopExperts] = useState([]);
  const checkWidth = window.matchMedia( "(min-width: 480px)" );

  const fetchData = async () => {
    try {
      setLoadingExperts(true);
      const { data: dataObject } = await getSearchExpert({
        name: '',
        sortBy: sortBy,
        sortByDesc: true,
        size: topK,
        page: 1,
        relatedOrgs: [],
        hIndexs: [],
        relatedTechs: [],
      });
      setDataTopExperts([...dataObject.data.expert_data]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingExperts(false);
    }
  };
 
  useEffect(()=>{
    if(!checkWidth.matches) setPageSize(1);
  },[])

  useEffect(() => {
    fetchData();
  }, [sortBy, pageNumber]);

  const renderListItemExpert = () => {
    let getItem = currentPage * pageSize;
    let dataPage = Object.values(dataExperts)?.filter((item, index) => {
      return index >= getItem && index < getItem + pageSize;
    });
    if(dataPage.length>1){
      let temp = dataPage[0];
      dataPage[0] = dataPage[1];
      dataPage[1] = temp;
    }
    return dataPage?.map((item, index) => (
      <div
        key={item.user_id}
        className="d-flex flex-column justify-content-start align-items-center mx-3"
        style={{ display: 'inline-block', position: 'relative' }}
      >
        {item.avatar_url && 
        <div
          className={`${
            index == 1
              ? 'is-active-image-avatar avatar'
              : 'avatar'
          }`}
        >
        <img  
          onError={(e) => {
            e.currentTarget.src = '/images/avatar/default.jpg';
          }}
          src={item.avatar_url} 
          alt="" 
        />
        </div>
        }
        <a href={`/expert/${item.id}`} style={{textDecoration:"none"}}>
        <div
          id="expert"
          className={`${
            index == 1 ? 'is-active' : ''
          } shadow d-flex flex-column justify-content-start align-items-center`}
        >
          <div
            id="name-expert"
            className="w-100 justify-content-center d-flex align-items-end"
          >
            <h6
              className="name-style"
              title={item.name.length > 22 ? item.name : ''}
            >
              {item.name.length > 22
                ? `${item.name.slice(0, 22)}...`
                : item.name}
            </h6>
          </div>
          <div className="d-flex flex-column justify-content-between align-items-center w-100">
            <div id="infor-expert" className="container">
              <div className="d-flex flex-column w-100">
                <div className="d-flex flex-row justify-content-between align-items-center flex-grow-1 my-2">
                  <div className="d-flex flex-row align-items-center justify-content-start">
                    <span className="material-icons me-1 d-none d-lg-block">
                      corporate_fare
                    </span>
                    <label className="text-expert-infor ms-1">
                      Organization:
                    </label>
                  </div>
                  <label
                    style={{ textTransform: 'capitalize' }}
                    title={
                      item.organisation_name != null &&
                      item.organisation_name.length > 13
                        ? item.organisation_name
                        : ''
                    }
                  >
                    {item.organisation_name != null &&
                    item.organisation_name.length > 13
                      ? `${item.organisation_name.slice(0, 13)}...`
                      : item.organisation_name}
                  </label>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center flex-grow-1 my-2">
                  <div className="d-flex flex-row align-items-center justify-content-start">
                    <span className="material-icons me-1 d-none d-lg-block">
                      analytics
                    </span>
                    <label className="text-expert-infor ms-1">I10-index:</label>
                  </div>
                  <label>{item.i10_index}</label>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center flex-grow-1 my-2">
                  <div className="d-flex flex-row align-items-center justify-content-start">
                    <span className="material-icons me-1 d-none d-lg-block">
                      segment
                    </span>
                    <label className="text-expert-infor ms-1">Citations:</label>
                  </div>
                  <label>{item.citations}</label>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center flex-grow-1 my-2">
                  <div className="d-flex flex-row align-items-center justify-content-start">
                    <span className="material-icons me-1 d-none d-lg-block">
                      leaderboard
                    </span>
                    <label className="text-expert-infor ms-1">H-Index:</label>
                  </div>
                  <label>{item.h_index}</label>
                </div>
              </div>
              <hr />
              <div id="explain" className="d-flex flex-column mt-4">
                <h6 className="mb-3">Specialties:</h6>
                <ul className="m-0 list-group">
                  {item.expertise_names.map((value, index) => (
                    <li className='text-capitalize' key={index}>{value}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        </a>
      </div>
    ));
  };

  return (
    <div id="experts" className='shadow'>
      <div
        id="header-experts"
        className="d-flex flex-row justify-content-between"
      >
        <div className="icon-school d-flex flex-row align-items-center">
          <span className="material-icons">school</span>
          <label className="ms-2">Experts</label>
        </div>
        <div id="btn-controls" className="d-flex flex-row">
          <div
            className="d-flex flex-column align-items-end"
            style={{ display: 'inline-block', position: 'relative' }}
          >
            <button
              type="button"
              style={{outline: 'none'}}
              className="btn border-0 d-flex justify-content-center align-items-center"
              onClick={() => setFilterMode(!filterMode)}
            >
              {/* <span className="material-icons">layers</span> */}
              <img src='/images/icons/layerButton.svg'/>
              <span className="material-icons ms-3" style={{fontSize:"28px"}}>{`${
                filterMode ? 'expand_less' : 'expand_more'
              }`}</span>
            </button>
            {filterMode && (
              <div
                id="filter"
                className="d-flex flex-column shadow rounded"
                style={{
                  zIndex: '2',
                  position: 'absolute',
                  backgroundColor: 'white',
                  marginTop: '34.1px',
                  width: '250px',
                }}
              >
                <div
                  className="w-100 d-flex flex-row align-items-center py-3 ps-3 rounded"
                  style={{ backgroundColor: '#27C1F1' }}
                >
                  <span className="material-icons text-white">settings</span>
                  <h2 className="text-white m-0">Setting</h2>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center px-3 mt-4">
                  <label
                    className="fw-bold"
                    style={{ fontSize: '15px', fontFamily: 'Inter' }}
                  >
                    Pages
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Pages"
                    value={pageNumber}
                    onChange={(e) => {
                      setPageNumber(e.target.value);
                      setRandomNumber(Math.random());
                    }}
                    style={{ width: '150px', fontSize: '13px' }}
                  />
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center px-3 my-4">
                  <label
                    className="fw-bold"
                    style={{ fontSize: '15px', fontFamily: 'Inter' }}
                  >
                    Sort
                  </label>
                  <select
                    className="form-select"
                    placeholder="Limit"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{ width: '150px', fontSize: '13px' }}
                  >
                    <option value="citations" style={{ fontSize: '13px' }}>
                      Citations
                    </option>
                    <option value="i10_index" style={{ fontSize: '13px' }}>
                      I10_index
                    </option>
                    <option value="h_index" style={{ fontSize: '13px' }}>
                      H_index
                    </option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        id="experts-bg"
        style={{ backgroundImage: 'url(/images/background/experts_bg.svg)' }}
      >
        <div className="experts-content d-flex flex-row align-items-center justify-content-between">
          <button
            type="button"
            className="btn btn-back border-0"
            onClick={() => {
              if (currentPage > 0) setCurrentPage(currentPage - 1);
              else setCurrentPage(pageNumber - 1)
            }}
          >
            <span className="material-icons">arrow_back_ios</span>
          </button>
          <div
            id="list-experts"
            className={`d-flex flex-row ${
              loadingExperts || pageSize==1
                ? 'justify-content-center'
                : 'justify-content-between'
            } w-100`}
          >
            {loadingExperts || !dataExperts.length ? <Spinner /> : renderListItemExpert()}
          </div>
          <button
            type="button"
            className="btn btn-forward border-0"
            onClick={() => {
              if (currentPage < pageNumber - 1) setCurrentPage(currentPage + 1);
              else setCurrentPage(0);
            }}
          >
            <span className="material-icons">arrow_forward_ios</span>
          </button>
        </div>
        {loadingExperts ? (
          ''
        ) : (
          <div className="nav-pages d-flex align-items-center justify-content-center">
            {Array.from({ length: pageNumber }, (item, index) => (
              <button
                key={index + randomNumber}
                type="button"
                className="btn border-0 p-0 mx-2"
                onClick={() => setCurrentPage(index)}
              >
                <span
                  className={`material-icons m-0 ${
                    currentPage == index && 'currentPage'
                  }`}
                >
                  circle
                </span>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
