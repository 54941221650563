import { MdPrecisionManufacturing, MdAgriculture } from 'react-icons/md';
import { FaBiohazard, FaBattleNet, FaEnvira } from 'react-icons/fa';
import { GiMaterialsScience, GiMedicalPack } from 'react-icons/gi';
import { IoMdGitNetwork } from 'react-icons/io';

const sectors = [
  {
    title: 'Biotechnology',
    icon: <FaBiohazard />,
  },
  { title: 'ICT', icon: <IoMdGitNetwork /> },
  { title: 'Agricultural Technology', icon: <MdAgriculture /> },
  { title: 'Energy Technology', icon: <FaBattleNet /> },
  { title: 'Environmental Technology', icon: <FaEnvira /> },
  { title: 'Manufacturing Technology', icon: <MdPrecisionManufacturing /> },
  { title: 'Medical Technology', icon: <GiMedicalPack /> },
  { title: 'Material Technology', icon: <GiMaterialsScience /> },
];

export default sectors;
