import React, { useState, useRef, useEffect } from 'react';
import CustomLink from './CustomLink';
import './Header.css';
import { Link } from 'react-router-dom';

export default function Header() {
  const offCanvas = useRef(null);
  const offCanvasRef = useRef(null);

  useEffect(() => {
    offCanvas.current = new bootstrap.Offcanvas(offCanvasRef.current, {});
  }, []);

  return (
    <header id="header">
      <div className="background background-bg">
        <div className="background background-bg1">
          <div className="background background-shadow"></div>
        </div>
      </div>
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <nav
            className="navbar navbar-expand-lg navbar-tabnet-mobile"
            style={{ display: 'none' }}
          >
            <div className="container-fluid" style={{ paddingLeft: '0' }}>
              <button
                className="btn btn-menu"
                type="button"
                onClick={(e) => offCanvas.current.show()}
              >
                <span className="material-icons" style={{ color: 'white' }}>
                  menu
                </span>
              </button>
            </div>
          </nav>
          <a className="navbar-brand p-0" href="/">
            <img className="logo" src="/images/logos/Union.svg" />
          </a>
        </div>
        <nav className="navbar navbar-expand-lg navbar-destop">
          <div className="container-fluid">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 h4">
              <CustomLink to="/">Home</CustomLink>
              <CustomLink to="/organisation">Organisation</CustomLink>
              <CustomLink to="/expert">Expert</CustomLink>
              <CustomLink to="/technology">Technology</CustomLink>
              {/* <CustomLink to="/firm">Firm</CustomLink> */}
              {/* <CustomLink to="/project">Product & Solution</CustomLink>
              <CustomLink to="/association">Association</CustomLink> */}
            </ul>
          </div>
        </nav>
        <div className="btn-container">
          {/* <button className="login-btn btn">Login</button> */}
          <button type="button" className="contact-us btn btn-warning">
            <Link to="/about-us">Contact Us</Link>
          </button>
        </div>
      </div>
      <nav className="navbar navbar-tabnet-mobile" style={{ display: 'none' }}>
        <div
          className="offcanvas offcanvas-start"
          data-bs-scroll="true"
          tabIndex="-1"
          ref={offCanvasRef}
          style={{ backgroundColor: '#061c4d', width: '100%' }}
        >
          <div className="offcanvas-header d-flex align-items-center">
            {/* <h3 className="offcanvas-title" style={{color:"white"}}>Menu</h3> */}
            <button
              type="button"
              className="btn p-0 d-flex align-items-center"
              style={{ color: 'white' }}
              onClick={(e) => offCanvas.current.hide()}
            >
              <span className="material-icons">close</span>
            </button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 h4 align-items-center">
              <CustomLink to="/">Home</CustomLink>
              <CustomLink to="/organisation">Organisation</CustomLink>
              <CustomLink to="/expert">Expert</CustomLink>
              <CustomLink to="/technology">Technology</CustomLink>
              {/* <CustomLink to="/firm">Firm</CustomLink> */}
              {/* <CustomLink to="/project">Product & Solution</CustomLink>
              <CustomLink to="/association">Association</CustomLink> */}
            </ul>
          </div>
        </div>
      </nav>
      <div
        className="ellipse-light"
        style={{ backgroundImage: 'url(/images/Ellipse/Ellipse 1.svg)' }}
      ></div>
      <div
        className="ellipse-dark"
        style={{ backgroundImage: 'url(/images/Ellipse/Ellipse 2.svg)' }}
      ></div>
    </header>
  );
}
