import { httpService } from '../services';
import { API } from '../endPoint';

const getSearchTechnologies = async ({
  name,
  size,
  page,
  sortBy,
  sortByDesc,
  expertSizes,
}) => {
  let url = API.GET_SEARCH_TECHNOLOGIES;

  if (name) {
    url += `name=${encodeURIComponent(`${name}`)}&`;
  }
  if (size) {
    url += `size=${encodeURIComponent(`${size}`)}&`;
  }

  if (page) {
    url += `page=${encodeURIComponent(`${page}`)}&`;
  }
  if (sortBy) {
    const sortDir = sortByDesc ? 'desc' : 'asc';
    url += `sort=${encodeURIComponent(`${sortBy}`)}-${sortDir}&`;
  }
  if (expertSizes && expertSizes.length > 0) {
    expertSizes.forEach((item) => {
      url += `list_filter_related=${item}&`;
    });
  }
  url = url.replace(/[?&]$/, '');

  const response = await httpService.post(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getAllTechnologies = async () => {
  let url = API.GET_ALL_TECHNOLOGIES;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return [];
  }
  return response.data;
};

const getKeywordsFromSector = async (sectorName) => {
  let url = API.GET_KEYWORDS_FROM_SECTOR + 'name=' + sectorName;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return [];
  }
  return response.data;
};

const getTopTechnologies = async (count) => {
  let url = `${API.GET_TOP_TECHNOLOGIES}top_k=${count}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return [];
  }
  return response.data;
};

const getTechnologiesById = async (id) => {
  let url = API.GET_TECHNOLOGIES_BY_ID + id;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getTechnologiesNetwork = async (id) => {
  let url = `${API.GET_TECHNOLOGIES_NETWORK}id=${id}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getRelatedExpert = async (page, limit) => {
  let url = `${API.GET_RELATED_EXPERTS}page=${page}&limit=${limit}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);
  if (response.code !== 200) {
    return null;
  }
  return response.data;
};

export {
  getSearchTechnologies,
  getAllTechnologies,
  getTopTechnologies,
  getTechnologiesById,
  getTechnologiesNetwork,
  getRelatedExpert,
  getKeywordsFromSector,
};
