import './FirmDetail.scss';
import { useEffect, useState } from 'react';
import FirmMap from './FirmMap';
import StackedChart from './StackedChart';
import RatingStar from './RatingStar';
import { useParams } from 'react-router-dom';
import { getFirmById, getReview } from '../../../commons/apis/services/firm';
import Spinner from '../../Spinner';
import Providers from './Providers';
import { formatDataChart } from '../FirmContent';
import axios from 'axios';

const providers = [
  {
    name: 'Netguru',
    logo: 'https://scontent-hkt1-1.xx.fbcdn.net/v/t31.18172-8/14570722_10154542498264795_3370781759830102119_o.png?_nc_cat=1&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=PiiInnmOoGAAX-dDVqY&_nc_ht=scontent-hkt1-1.xx&oh=00_AT_e6jZO1yhUOYLsgZaAddh_LP5QSK-uqkyVFFmG4z3jBg&oe=6370B148',
    ratings: 4.8,
    expertises: [],
    address: 'Ahmedabad, India',
    salary: '<25$ / h',
    people: '250-999',
  },
  {
    name: 'Algoworks',
    logo: 'https://images.yourstory.com/cs/images/companies/600fa3f0ad8b-Algoworkslogo1-1647514588979.jpg?fm=auto&ar=1:1&mode=fill&fill=solid&fill-color=fff',
    ratings: 4.9,
    expertises: [],
    address: 'Sunnyvale, CA',
    salary: '<25$ / h',
    people: '250-999',
  },
  {
    name: 'December Labs',
    logo: 'https://clutchco-static.s3.amazonaws.com/s3fs-public/logos/logo_mark_v2.png?VersionId=j0VquYy5Rh3_Uc99cfrsS.9WQYFJOl0n',
    ratings: 4.9,
    expertises: [],
    address: 'Houston, TX',
    salary: '<25$ / h',
    people: '250-999',
  },
];

const fakeFirm = {
  logo: 'https://clutchco-static.s3.amazonaws.com/s3fs-public/logos/logo_new.jpg?VersionId=3ZnQbf5.aGaECoSaRrVQ3DLHF1vFauAn',
  name: 'Hyperlink InfoSystem ',
  slogan: 'Best Android & iPhone App Development Services',
  firm_url: 'https://www.hyperlinkinfosystem.com/',
  location: 'Ahmedabad, India',
  latlng: { lat: 21.0245, lng: 105.84117 },
  desc: `Hyperlink Infosystem, an ISO 9001:2008 certified organization, is a
  renowned IT Software Solutions provider based in Ahmedabad, India.
  Established in 2011, Hyperlink InfoSystem, with it's dedicated and
  skilled team of professionals has able to create dynamic and cost
  effective solutions for it's clients that are based all over the USA,
  UK, Japan, Canada and other parts of the World. We believe in
  delivering services without compromising on time or quality.`,
  portfolioImg: [
    '/images/firm-portfolio/Rectangle 41.png',
    '/images/firm-portfolio/Rectangle 42.png',
    '/images/firm-portfolio/Rectangle 43.png',
    '/images/firm-portfolio/Rectangle 44.png',
    '/images/firm-portfolio/Rectangle 45.png',
    '/images/firm-portfolio/Rectangle 46.png',
  ],
};

const objectToArray = (obj) => {
  const result = Object.keys(obj).map((key) => [key, obj[key]]);
  return result;
};

const FirmDetail = () => {
  const [openReadMore, setOpenReadMore] = useState(false);
  const [sortBy, setSortBy] = useState('Relevence');
  const [firm, setFirm] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [review, setReview] = useState({});
  const [loadingReview, setLoadingReview] = useState(true);
  const [latLon, setLatLon] = useState({})
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getFirmById(id);
      setFirm(res.item);
      var location = res.item.locations[0].location.toLowerCase();
      if(location.includes('hanoi') || location.includes('ha noi'))
        location = 'hanoi'
      else if(location.includes('danang' || location.includes('da nang')))
        location = 'danang'
      else if(location.includes('ho chi minh'))
        location = 'Ho Chi Minh City'
      const locationSearch = (await axios.get(
        `http://api.positionstack.com/v1/forward?access_key=72843f8b0347d259c1e9cd0fbb9840c7&query=${location}`,
      )).data.data[0]
      setLatLon({
          lat: locationSearch?.latitude ?? 21.01868,
          long: locationSearch?.longitude ?? 105.72979
      })
      if (id === '86c50ed9889caaad327a36b65331b03838c70033') {
        setLatLon({
          lat: 21.0304203,
          long: 105.7843627,
        });
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getReview(id);
      setReview(res.data.items[0]);
      console.log(res.data.items[0]);
      setLoadingReview(false);
    };
    fetchData();
  }, []);

  const handleReadMore = () => {
    setOpenReadMore(true);
  };

  return isLoading ? (
    <div className="d-flex flex-grow-1 justify-content-center content">
      <Spinner />
    </div>
  ) : (
    <div className="content" id="org-detail">
      <div className="org-header row">
        <div className="col-1 org-img">
          <img src={firm.logo} />
        </div>
        <div className="col-11 org-name">
          <div>
            <h2>{firm.name}</h2>
          </div>
        </div>
      </div>
      <div className="content-detail mb-5">
        {/* <p className="firm-slogan">{fakeFirm.slogan}</p> */}
        <a
          className="firm-red-text firm-url"
          href={firm.website}
          target="_blank"
        >
          visit website{' '}
          <span style={{ marginLeft: '13px', fontSize: 18 }}>&#62;</span>
        </a>
        <div className="row gx-5">
          <div className="col-10">
            {openReadMore ? (
              <p className="firm-des">{firm.summary_description.description}</p>
            ) : (
              <>
                <p className="firm-des">
                  {firm.summary_description.description.length > 550
                    ? `${firm.summary_description.description.substring(
                        0,
                        550,
                      )}...`
                    : firm.summary_description.description}
                </p>
                <p onClick={handleReadMore} className="firm-red-text">
                  Read more...
                </p>
              </>
            )}
          </div>
          <div className="col-2">
            <div className="row mb-4">
              <span className="material-icons icon-note col-2">label</span>
              <span className="text-note col">
                {firm.summary_description.min_project_size}
              </span>
            </div>
            <div className="row mb-4">
              <span className="material-icons icon-note col-2">paid</span>
              <span className="text-note col">
                {firm.summary_description.hourly_rate}
              </span>
            </div>
            <div className="row mb-4">
              <span className="material-icons icon-note col-2">people</span>
              <span className="text-note col">
                {firm.summary_description.nums_employee}
              </span>
            </div>
            <div className="row mb-4">
              <span className="material-icons icon-note col-2">flag</span>
              <span className="text-note col">
                {firm.summary_description.founded_year}
              </span>
            </div>
          </div>
        </div>
      </div>
      <section className="firm-section">
        <h2 className="firm-heading">
          <span className="material-icons heading-icon">location_on</span>
          {firm.locations[0].location}
        </h2>
        <FirmMap firm={firm} latLon={latLon} />
      </section>
      {/* Temporarily hide provider because lack of data */}
      {/* <Providers providers={providers} /> */}
      <section className="firm-section">
        <h2 className="firm-heading">
          <span className="material-icons heading-icon">control_camera</span>
          Focus
        </h2>
        <p className="grey-text">Service Line</p>
        <StackedChart
          data={formatDataChart(firm.service_focus.service_lines).objType}
        />
        <div className="row">
          {objectToArray(firm.service_focus).map((service) =>
            service[0] === 'service_lines' ? undefined : (
              <div className="col-md-6 col-sm-12">
                <p className="grey-text">
                  {service[0]
                    .split('_')
                    .map((word) => word[0].toUpperCase() + word.substring(1))
                    .join(' ')}
                </p>
                <StackedChart data={formatDataChart(service[1]).objType} />
              </div>
            ),
          )}
        </div>
      </section>
      <section className="firm-section">
        <h2 className="firm-heading">
          <span className="material-icons heading-icon">inventory</span>
          Portfolio
        </h2>
        <div className="portfolio-img-container">
          {/* TODO: sai chính tả */}
          {firm.porfolio.projects?.slice(0, 6).map((obj) => (
            <div className="portfolio-img-hover">
              <img
                className="portfolio-img"
                src={obj.img}
                key={obj.img}
                alt={obj.name}
              />
            </div>
          ))}
        </div>
      </section>
      <section className="firm-section">
        <h2 className="firm-heading">
          <span className="material-icons heading-icon">star</span>
          Reviews
        </h2>
        <div className="d-flex gap-5" style={{ color: '#0A2A63' }}>
          <div className="d-flex align-items-center gap-3">
            <span
              style={{ fontWeight: 600 }}
              className="h3 mb-0 font-weight-bold"
            >
              {firm.summary_description.agg_rating}
            </span>
            <RatingStar
              stars={Math.ceil(firm.summary_description.agg_rating)}
            />
            <span className="text-uppercase h5 mb-0">
              {firm.summary_description.total_rating}
            </span>
          </div>
          <div className="sort d-flex flex-row align-items-center my-4">
            <h5>Sort by</h5>
            <select
              className="border-0 form-select"
              style={{ fontWeight: 700 }}
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="Relevence">Relevence</option>
              <option value="Ratings">Ratings</option>
            </select>
          </div>
        </div>
        {loadingReview ? (
          <Spinner />
        ) : review ? (
          <div style={{ padding: '20px' }} className="content-detail">
            <div className="row">
              <div className="col-3 review-block">
                <div className="border-bottom">
                  <p className="grey-text text-uppercase">the project</p>
                  <h1 className="mb-4">{review.project_review.name}</h1>
                  <div className="row mb-4">
                    <span className="material-icons icon-note col-1">
                      layers
                    </span>
                    <span className="text-note col">
                      {review.project_review.category}
                    </span>
                  </div>
                  <div className="row mb-4">
                    <span className="material-icons icon-note col-1">
                      label
                    </span>
                    <span className="text-note col">
                      {review.project_review.size}
                    </span>
                  </div>
                  <div className="row mb-4">
                    <span className="material-icons icon-note col-1">
                      date_range
                    </span>
                    <span className="text-note col">
                      {review.project_review.length}
                    </span>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="review-title">Project summary:</p>
                  <p style={{ lineHeight: '22px' }} className="grey-text">
                    {review.project_review.summary}
                  </p>
                </div>
              </div>
              <div className="col-6 review-block">
                <div className="row">
                  <div className="col-8 border-bottom">
                    <p className="grey-text text-uppercase pb-4">the review</p>
                    <p className="review-quote">{review.review.review}</p>
                    <p className="grey-text text-uppercase">
                      {review.review.date}
                    </p>
                  </div>
                  <div className="col-4">
                    <div className="d-flex align-items-center gap-3 pb-3 border-bottom">
                      <p className="h2 mb-0 font-weight-bold">
                        {review.review.score_rating}
                      </p>
                      <RatingStar
                        stars={Math.ceil(review.review.score_rating)}
                      />
                    </div>
                    <div className="row mt-3">
                      <div className="col-8">
                        <p className="grey-text">Quality:</p>

                        <p className="grey-text">Schedule:</p>
                        <p className="grey-text">Cost:</p>
                        <p className="grey-text">Willing to refer:</p>
                      </div>
                      <div className="col-4">
                        {review.review.score_list.map((score) => (
                          <p key={score.split(':')[0]} className="grey-text">
                            {score.split(':')[1]}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 p-2">
                  <p className="review-title">Feedback summary:</p>
                  <p style={{ lineHeight: '22px' }} className="grey-text">
                    {review.review.summary}
                  </p>
                  <div className="d-flex justify-content-end mx-5">
                    <button className="provider-btn text-capitalize d-flex align-items-center">
                      read full review{' '}
                      <span
                        style={{ color: '#ffffff', fontSize: 24 }}
                        className="material-icons"
                      >
                        expand_more
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="border-bottom pb-3">
                  <p className="grey-text text-uppercase">the review</p>
                  <h1 className="mb-4">{review.reviewer.title}</h1>
                  <div className="d-flex align-items-center gap-3">
                    <span style={{ fontSize: 75 }} className="material-icons">
                      account_circle
                    </span>
                    <p className=" grey-text m-0">
                      {review.reviewer.name
                        ? review.reviewer.name
                        : 'Anonymous'}
                    </p>
                  </div>
                </div>
                <div className="mt-4 ">
                  <div className="row mb-4">
                    <span className="material-icons icon-note col-1">
                      apartment
                    </span>
                    <span className="text-note col">
                      {review.reviewer.org_industry}
                    </span>
                  </div>
                  <div className="row mb-4">
                    <span className="material-icons icon-note col-1">
                      people
                    </span>
                    <span className="text-note col">
                      {review.reviewer.org_size}
                    </span>
                  </div>
                  {/* <div className="row mb-4">
                    <span className="material-icons icon-note col-1">
                      location_on
                    </span>
                    <span className="text-note col">Singapore</span>
                  </div>
                  <div className="row mb-4">
                    <span className="material-icons icon-note col-1">call</span>
                    <span className="text-note col">Phone Interview</span>
                  </div> */}
                  <div className="row mb-4">
                    <span className="material-icons icon-note col-1">
                      task_alt
                    </span>
                    <span className="text-note col">Verified</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default FirmDetail;
