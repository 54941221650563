import { useState, useEffect } from 'react';
import { Typography, AccordionDetails, Button } from '@mui/material';
import { AiFillFlag } from 'react-icons/ai';
import { MdApi, MdFilterList, MdWork } from 'react-icons/md';
import { IconContext } from 'react-icons';
import { GiGraduateCap } from 'react-icons/gi';
import '../../pages/organisation/OrganisationPage.scss';
import { getAllTechnologies } from '../../commons/apis/services/technologies';
import { getAllLocations } from '../../commons/apis/services/map';
import Spinner from '../Spinner';
import { ThemeProvider } from '@mui/material/styles';
import CustomizedHook from '../AutocompleteSearch';
import {
  theme,
  accordionTheme,
  Accordion,
  AccordionSummary,
  FilterHeader,
  FilterAccordionSummary,
} from '../../commons/components-lib';
import { useMediaQuery } from 'react-responsive';

const TechFilter = ({ onTechChange, resetFilter, state, triggerReset }) => {
  const [techList, setTechList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const res = await getAllTechnologies();
      setTechList(res);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <IconContext.Provider
            value={{ color: '#2770F1', className: 'info-icon', size: 25 }}
          >
            <MdApi />
          </IconContext.Provider>
          <Typography fontSize={16}>Technologies</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Spinner />
        ) : (
          <CustomizedHook
            options={techList.map((tech) => tech.name)}
            label="Choose technologies"
            onChange={onTechChange}
            resetFilter={resetFilter}
            triggerReset={triggerReset}
            state={state}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const ExpertFilter = ({
  onExpertChange,
  relatedExperts,
  resetFilter,
  state,
  triggerReset,
}) => {
  const expertFilter = [
    { label: '< 20', value: '0-19' },
    { label: '20 - 50', value: '20-50' },
    { label: '> 50', value: '51-max' },
  ];

  const handleChange = (selectedLabels) => {
    const selectedObj = expertFilter.filter((o) =>
      selectedLabels.includes(o.label),
    );
    onExpertChange(selectedObj.map((o) => o.value));
  };

  useEffect(() => {
    relatedExperts.splice(0, relatedExperts.length);
    onExpertChange([]);
  }, [resetFilter]);

  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel2d-header">
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <IconContext.Provider
            value={{ color: '#2770F1', className: 'info-icon', size: 25 }}
          >
            <GiGraduateCap />
          </IconContext.Provider>
          <Typography fontSize={16} noWrap={true}>
            Related Experts
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CustomizedHook
          options={expertFilter.map((e) => e.label)}
          label="Choose related experts"
          onChange={handleChange}
          resetFilter={resetFilter}
          triggerReset={triggerReset}
          state={state}
        />
      </AccordionDetails>
    </Accordion>
  );
};
const CountryFilter = ({
  onCountryChange,
  resetFilter,
  state,
  triggerReset,
}) => {
  const [countryList, setCountryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const res = await getAllLocations('country');
      setCountryList(res);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel3d-header">
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <IconContext.Provider
            value={{ color: '#2770F1', className: 'info-icon', size: 25 }}
          >
            <AiFillFlag />
          </IconContext.Provider>
          <Typography fontSize={16} noWrap={true}>
            Countries
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Spinner />
        ) : (
          <CustomizedHook
            options={countryList}
            haveIcon={true}
            label="Choose countries"
            onChange={onCountryChange}
            resetFilter={resetFilter}
            triggerReset={triggerReset}
            state={state}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const CompanyFilter = () => {
  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <IconContext.Provider
            value={{ color: '#2770F1', className: 'info-icon', size: 25 }}
          >
            <MdWork />
          </IconContext.Provider>
          <Typography fontSize={16}>Company Type</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada
          lacus ex, sit amet blandit leo lobortis eget.
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const Filter = ({
  onTechChange,
  onCountryChange,
  onExpertChange,
  onFilterChange,
  onTriggerFilter,
  onSearch,
  relatedExperts,
  state,
}) => {
  const [resetFilter, setResetFilter] = useState(false);
  const [triggerReset, setTriggerReset] = useState(false);
  const isSmaller = useMediaQuery({ maxWidth: 1040 });

  const handleApplyFilter = () => {
    onFilterChange(true);
    onTriggerFilter();
    onSearch && onSearch();
    setTriggerReset(false);
  };

  const handleResetFilter = () => {
    setResetFilter(!resetFilter);
    onFilterChange(true);
    onTriggerFilter();
    setTriggerReset(true);
  };

  return (
    <div className="filter">
      {isSmaller ? (
        <ThemeProvider theme={accordionTheme}>
          <Accordion>
            <FilterAccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <FilterHeader />
            </FilterAccordionSummary>
            <AccordionDetails>
              <div className="filter-main">
                <TechFilter
                  onTechChange={onTechChange}
                  resetFilter={resetFilter}
                  state={state?.relatedTech}
                  triggerReset={triggerReset}
                />
                <ExpertFilter
                  onExpertChange={onExpertChange}
                  resetFilter={resetFilter}
                  relatedExperts={relatedExperts}
                  state={state?.relatedExperts}
                  triggerReset={triggerReset}
                />
                <CountryFilter
                  onCountryChange={onCountryChange}
                  resetFilter={resetFilter}
                  state={state?.countries}
                  triggerReset={triggerReset}
                />
                {/* <CompanyFilter /> */}
              </div>
              <div className="filter-btn-container">
                <ThemeProvider theme={theme}>
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<MdFilterList />}
                    fullWidth
                    style={{ marginTop: 10, fontSize: 12, fontWeight: 600 }}
                    onClick={handleApplyFilter}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<MdFilterList />}
                    fullWidth
                    style={{
                      marginTop: 10,
                      fontSize: 12,
                      fontWeight: 600,
                      background: '#ffffff',
                      color: '#3D56B2',
                      borderColor: '#3D56B2',
                    }}
                    onClick={handleResetFilter}
                  >
                    Reset Filter
                  </Button>
                </ThemeProvider>
              </div>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
      ) : (
        <>
          <FilterHeader />
          <div className="filter-main">
            <ThemeProvider theme={accordionTheme}>
              <TechFilter
                onTechChange={onTechChange}
                resetFilter={resetFilter}
                state={state?.relatedTech}
                triggerReset={triggerReset}
              />
              <ExpertFilter
                onExpertChange={onExpertChange}
                resetFilter={resetFilter}
                relatedExperts={relatedExperts}
                state={state?.relatedExperts}
                triggerReset={triggerReset}
              />
              <CountryFilter
                onCountryChange={onCountryChange}
                resetFilter={resetFilter}
                state={state?.countries}
                triggerReset={triggerReset}
              />
            </ThemeProvider>
          </div>
          <div className="filter-btn-container">
            <ThemeProvider theme={theme}>
              <Button
                color="primary"
                variant="contained"
                endIcon={<MdFilterList />}
                fullWidth
                style={{ marginTop: 10, fontSize: 12, fontWeight: 600 }}
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
              <Button
                variant="contained"
                endIcon={<MdFilterList />}
                fullWidth
                style={{
                  marginTop: 10,
                  fontSize: 12,
                  fontWeight: 600,
                  background: '#ffffff',
                  color: '#3D56B2',
                  borderColor: '#3D56B2',
                }}
                onClick={handleResetFilter}
              >
                Reset Filter
              </Button>
            </ThemeProvider>
          </div>
        </>
      )}
    </div>
  );
};

export default Filter;
