import '../../../pages/organisation/OrganisationPage.scss';
import './OrganisationDetail.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Experts from './Experts';
import PublicationsChart from './PublicationsChart';
import ProminentProfiles from './ProminentProfiles';
import Spinner from '../../Spinner';

import { getOrganizationById } from '../../../commons/apis/services/organizations';
import { logEvent } from 'firebase/analytics';
import analytics from '../../../commons/firebase/index.js';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};
const OrganisationDetail = () => {
  const { id } = useParams();
  const [org, setOrg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (id) {
      logEvent(analytics, 'view_detail_org', {
        org_id: id,
        page_path: window.location.pathname,
        content_id: id
      })
      const getData = async () => {
        try {
          setLoading(true);
          const { data } = await getOrganizationById(id);
          setOrg(data.data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      getData();
    }
  }, [id]);

  return (
    <div className="content" id="org-detail">
      <div className="org-header row">
        <div className="org-avatar-col-3 col-1 org-img">
          <img src={org.img} />
        </div>
        <div className="org-avatar-col-9 col-11 org-name">
          <div>
            <h2>{org.name}</h2>
            <span>
              {org.locations && org.locations.length > 0
                ? org.locations[0].locations
                  ? org.locations[0].locations
                  : org.locations[0].city + ', ' + org.locations[0].country
                : ''}
            </span>
          </div>
        </div>
      </div>
      <div className="bar d-flex w-100 flex-row border justify-content-between align-items-center form-control p-0 mt-3">
        <div className="related d-flex flex-row flex-grow-1 py-3 justify-content-start ps-4">
          <div className="d-flex flex-column justify-content-center align-items-center border-end">
            <h6>Related Experts</h6>
            <label>{org.related_authors}</label>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h6 style={{ color: '#42BBFF' }}>Related Technologies</h6>
            <label>{org.expertise_name?.length ?? 0}</label>
          </div>
        </div>
      </div>
      <div className="content-detail">
        <div className="desc">{org.Org_des}</div>

        <div className="content-tab">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="About" {...a11yProps(0)} />
                {/* <Tab label="Technology Focus" {...a11yProps(1)} />
                <Tab label="Ego Network" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="info">
                <div className="info-item row">
                  <span className="col">Website</span>
                  <div className="col-10">
                    <a href={org.Website} target="_blank">
                      {org.Website}
                    </a>
                  </div>
                </div>
                <div className="info-item row">
                  <span className="col">Company Size</span>
                  <div className="col-10">{org.Company_size}</div>
                </div>
                <div className="info-item row">
                  <span className="col">Headquarter</span>
                  <div className="col-10">{org.Head_quater}</div>
                </div>
                <div className="info-item row">
                  <span className="col">Industry</span>
                  <div className="col-10">{org.Industry}</div>
                </div>
                <div className="info-item row">
                  <span className="col">Founded</span>
                  <div className="col-10">{org.Founded}</div>
                </div>
                <div className="info-item row">
                  <span className="col">Specialties</span>
                  <div
                    style={{
                      textTransform: 'capitalize',
                    }}
                    className="col-10"
                  >
                    {org.expertise_name && org.expertise_name.join(', ')}
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              Technology Focus
            </TabPanel>
            <TabPanel value={value} index={2}>
              Ego Network
            </TabPanel>
          </Box>
        </div>
      </div>
      <Experts org_id={org.id} />
      <div className="row mt-5 justify-content-between org-chart">
        <div className="col-5 box-shadow ">
          <PublicationsChart org_id={org.id} />
        </div>
        <div className="col-5 box-shadow ">
          <ProminentProfiles
            org_id={org.id}
            related_authors={org.related_authors}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganisationDetail;
