import {
  AiOutlineLeftCircle,
  AiTwotoneFilter,
  AiOutlineRightCircle,
} from 'react-icons/ai';
import { IconContext } from 'react-icons';
import '../../pages/organisation/OrganisationPage.scss';
import React from 'react';

const list = [
  { icon: <AiOutlineLeftCircle />, key: 0 },
  { icon: <AiTwotoneFilter />, key: 1 },
];

const Sidebar = ({ isOpen, onSelect }) => {
  const [selectedItem, setSelectedItem] = React.useState(1);
  const handleCollapse = () => {
    onSelect(!isOpen);
    setSelectedItem(0);
  };

  const handleOpenFilter = () => {
    onSelect(true);
    setSelectedItem(1);
  };

  return (
    <div className="menu">
      {list.map((el) => {
        const isSelected = selectedItem === el.key;
        return (
          <div
            className={`menu-icon-container ${isSelected ? 'selected' : ''}`}
            key={`filter${el.key}`}
            onClick={el.key === 0 ? handleCollapse : handleOpenFilter}
          >
            <IconContext.Provider
              value={{
                color: isSelected ? '#2770F1' : '#666666',
                className: 'menu-icon',
                size: 20,
              }}
            >
              {el.key === 0 ? (
                isOpen ? (
                  el.icon
                ) : (
                  <AiOutlineRightCircle />
                )
              ) : (
                el.icon
              )}
            </IconContext.Provider>
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;
