import { Link, useMatch, useResolvedPath } from 'react-router-dom';
const CustomLink = ({ children, to, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li className="nav-item px-2 mx-4" onClick={props.onClick}>
      <Link
        className={`nav-link d-flex justify-content-center ${
          match ? 'nav-active' : ''
        }`}
        to={to}
        {...props}
      >
        {children}
      </Link>
    </li>
  );
};

export default CustomLink;
