import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import { getListPaper } from '../commons/apis/services/expert';
import '../pages/homepage/Homepage.scss';
import { useEffect, useState } from 'react';
import Spinner from './Spinner';

const PublicationsChart = () => {
  const [papers, setPapers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getListPaper();
      const papers = res.data
        .map((e) => {
          return {
            year: e.year,
            publications: e.count,
          };
        })
      setPapers(papers);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="grid-2-cols">
      <div className="publication-diagram">
        <h5 className="title text-dark">Publications over time</h5>
        {isLoading ? (
          <Spinner size={15} />
        ) : (
          <ResponsiveContainer height={368}>
            <BarChart width={700} height={368} data={papers}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="publications" fill="#3986BF" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
      <div>
        <p className="title">Research analytics</p>
        <p className="description">
          Our system takes a deep investigation into the scholar experts's
          publications, the most important source to define the expertise of the
          experts in academia. The research publications present the most
          innovative ideas and prototype which have huge potential to be be
          commercialied and applied to real-world problems. There are various
          types of publications such as scholarly journals, conferences,
          professional and trade publications.
        </p>
        {/* <a href="/">
          <button type="button" className="btn-secondary">
            Learn More
          </button>
        </a> */}
      </div>
    </div>
  );
};

export default PublicationsChart;
