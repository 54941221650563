import '../../../pages/expert/ExpertPage.scss';
import './ExpertDetail.css';
import React,{ useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import { Pagination } from '@mui/material';
import Spinner from '../../Spinner';
import { getRelatedExpert } from '../../../commons/apis/services/expert.js';

export default function TabContentExpert(props){
  const [expert,setExpert] = useState(props.expert);
  const [currentTab,setCurrentTab] = useState(1);
  const [currentPageTrack,setCurrentPageTrack] = useState(1);
  const [currentPageEx,setCurrentPageEx] = useState(1);
  const [relatedExpertData,setRelatedExpertData] = useState([]);
  const [countRelatedEx,setCountRelatedEx] = useState();
  const [loadingRelatedExpert,setLoadingRelatedExpert] = useState(false);

  const fetchRelatedExpertsData = async (page=currentPageEx) => {
    try {
      setLoadingRelatedExpert(true);
      const { data: dataRelatedObject } = await getRelatedExpert({
        user_id: props.id,
        page: page,
        limit: 4,
      })
      setRelatedExpertData([...dataRelatedObject.data]);
      setCountRelatedEx(Math.ceil(dataRelatedObject.count/4));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingRelatedExpert(false);
    }
  };

  useEffect(() =>{
    fetchRelatedExpertsData();
  },[props.id])

  useEffect(()=>{
    if(props.expert) setExpert(props.expert);
  },[props.expert])

  const handleChangeTrack= (event, value) => {
    fetchRelatedExpertsData(value)
    setCurrentPageTrack(value);
  };

  const handleChangeEx= (event, value) => {
    setCurrentPageEx(value);
  };

  let currentTabValue;
  switch(currentTab){
    case 0:
      currentTabValue = <div className='technology-tab d-flex flex-column mt-5'>
        <span>We are focusing on the following technologies:</span>
        <div className='technology-tab-infor d-flex w-100 justify-content-center'>
          <img src="/images/chart/expert_technology_focus.png" />
        </div>
      </div>
      break;
    case 1:
      currentTabValue = props.papers && <div className='d-flex justify-content-center mt-5'>
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={[["Year", "Publications"], ...props.papers?.map((item) => [item.year, Number(item.count)])]}
          options={{
            colors: ['#3986BF'],
            legend: { position: "top" },
          }}
        />
      </div>
      break;
    case 2:
      currentTabValue = loadingRelatedExpert ? <Spinner /> : <div className='d-flex flex-column flex-grow-1 my-5'>
        {
          relatedExpertData?.map((item,index)=>
            <div key={index} className='track-tab d-flex flex-column flex-grow-1 justify-content-start align-items-between mb-5'>
              <h6>{item.title}</h6>
              <div className="linked">
                {item.author_list?.map((au, idx) =>
                  <><span>{au}</span><span>{idx != item.author_list.length - 1 && <span>, </span>}</span></>
                )}
              </div>
              <span>
                {item.publication}
              </span>
            </div>
          )
        }
        <div className='d-flex flex-grow-1 justify-content-center'>
          <Pagination
            count={countRelatedEx}
            page={currentPageTrack}
            onChange={handleChangeTrack}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
      break;
    case 3:
      if(!expert.working_experiences){
        currentTabValue = <h2 className='text-capitalize my-5 d-flex flex-grow-1 justify-content-center' style={{color:'#0A2A63'}}>no data</h2>
        break;
      }  
      let getItemExperiences = (currentPageEx-1) * 4;
      let dataPageExperiences = expert.working_experiences?.filter((item, index) => {
        return index >= getItemExperiences && index < getItemExperiences + 4;
      });
      currentTabValue = <div className='d-flex flex-column flex-grow-1 my-5'>
        {
          dataPageExperiences.map((item,index)=>
            <div key={index} className='ex-tab d-flex flex-column flex-grow-1 justify-content-start align-items-between mb-5'>
              <div className='ex-item d-flex flex-row flex-grow-1 justify-content-start align-items-center my-2'>
                <span className="material-icons ex-item-icon">calendar_month</span>
                <span className='ex-item-title'>Date</span>
                <label className='ex-item-text'>{item.start_date} - {item.end_date}</label>
              </div>
              <div className='ex-item d-flex flex-row flex-grow-1 justify-content-start align-items-center my-2'>
                <span className="material-icons ex-item-icon">work</span>
                <span className='ex-item-title'>Position</span>
                <label className='ex-item-text'>{item.position}</label>
              </div>
              <div className='ex-item d-flex flex-row flex-grow-1 justify-content-start align-items-center my-2'>
                <span className="material-icons ex-item-icon">home_work</span>
                <span className='ex-item-title'>Organization</span>
                <label className='ex-item-text'>{item.organisation}</label>
              </div>
              <div className='ex-item d-flex flex-row flex-grow-1 justify-content-start align-items-center my-2'>
                <span className="material-icons ex-item-icon">location_on</span>
                <span className='ex-item-title'>Working Location</span>
                <label className='ex-item-text'>{item.working_location}</label>
              </div>
            </div>
          )
        }
        <div className='d-flex flex-grow-1 justify-content-center'>
          <Pagination
            count={Math.ceil(expert.working_experiences.length/ 4)}
            page={currentPageEx}
            onChange={handleChangeEx}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
      
      break;
  }

  return <div className='tab-content d-flex flex-column border mt-4'>
    <div className='d-flex flex-row'>
      {/* <button className={`btn d-flex justify-content-center align-items-center ${currentTab==0?'btn-tab-content-active':'btn-tab-content'}`} style={{borderRadius:"6px 0px 0px 0px"}} 
        type='button' onClick={e=>setCurrentTab(0)}>
        Technology Focus
      </button> */}
      <button className={`btn d-flex justify-content-center align-items-center ${currentTab==1?'btn-tab-content-active':'btn-tab-content'}`} 
        type='button' onClick={e=>setCurrentTab(1)}>
        Publications
      </button>
      <button className={`btn d-flex justify-content-center align-items-center ${currentTab==2?'btn-tab-content-active':'btn-tab-content'}`} 
        type='button' onClick={e=>setCurrentTab(2)}>
        Track Record
      </button>
      {expert.working_experiences && 
        <button className={`btn d-flex justify-content-center align-items-center ${currentTab==3?'btn-tab-content-active':'btn-tab-content'}`} style={{borderRadius:"0px 6px 0px 0px"}} 
        type='button' onClick={e=>setCurrentTab(3)}>
        Working Experiences
      </button>
      }
      <div className='d-flex flex-grow-1 tab-content-border'></div>
    </div>
    {currentTabValue}
  </div>
}