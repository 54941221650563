import Pagination from '@mui/material/Pagination';
import { useState } from 'react';
import ExpertItems from './ExpertItems';
import Spinner from '../../Spinner';
import { useEffect } from 'react';
import { getRelatedExpertsOrg } from '../../../commons/apis/services/organizations';

const Experts = (props) => {
  const [page, setPage] = useState(1);
  const [expertsData, setExpertsData] = useState([]);
  const [experts, setExperts] = useState([]);
  const [sortBy, setSortBy] = useState('citations');
  const [isSortInDesc, setSortInDesc] = useState(true);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (props.org_id) {
      const fetchData = async () => {
        const res = await getRelatedExpertsOrg(props.org_id);
        setExpertsData(res.data?.data_related_expert ?? []);
        setLoading(false);
      };
      fetchData();
    }
  }, [props.org_id]);
  useEffect(() => {
    const experts = expertsData.sort(compare).slice((page - 1) * 10, page * 10);
    setExperts(experts);
  }, [page, expertsData, isSortInDesc, sortBy]);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const compare = (a, b) => {
    if (isSortInDesc) {
      if (sortBy == 'citations') return a.citations > b.citations ? -1 : 1;
      else if (sortBy == 'h_index') return a.h_index > b.h_index ? -1 : 1;
      else return a.i10_index > b.i10_index ? -1 : 1;
    } else {
      if (sortBy == 'citations') return a.citations < b.citations ? -1 : 1;
      else if (sortBy == 'h_index') return a.h_index < b.h_index ? -1 : 1;
      else return a.i10_index < b.i10_index ? -1 : 1;
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="expert-list">
      <div className="d-flex justify-content-between align-items-start sort-bar">
        <div>
          <span>Related Experts: </span>
          <span className="text-blue">{expertsData.length}</span>
        </div>
        <div className="d-flex align-items-center">
          <span>Sort by </span>
          <select
            className="border-0 text-blue"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="citations" style={{ fontSize: '13px' }}>
              Citations
            </option>
            <option value="i10_index" style={{ fontSize: '13px' }}>
              I10_index
            </option>
            <option value="h_index" style={{ fontSize: '13px' }}>
              H_index
            </option>
          </select>
          <button
            className="btn btn-light d-flex align-items-center border-0 p-0"
            title="Sort"
            onClick={() => {
              setSortInDesc(!isSortInDesc);
            }}
          >
            <span className="material-icons">unfold_more</span>
          </button>
        </div>
      </div>

      {experts &&
        experts.map((expert) => (
          <ExpertItems expert={expert} key={expert.id} />
        ))}
      <Pagination
        count={Math.ceil(expertsData.length / 10)}
        page={page}
        onChange={handleChange}
        variant="outlined"
        shape="rounded"
      />
    </div>
  );
};

export default Experts;
