import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import { publicationsData } from '../../mock-data';
import { getListPaperOrg } from '../../../commons/apis/services/organizations';
import Spinner from '../../Spinner';
import { useState ,useEffect} from 'react';

const PublicationsChart = (props) => {
  const [papers, setPapers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.org_id) {
      const fetchData = async () => {
        const res = await getListPaperOrg(props.org_id);
        const papers = res.data
          .map((e) => {
            return {
              year: e.year,
              publications: e.count,
            };
          })
        setPapers(papers);
        setIsLoading(false);
      };
      fetchData();
    }
  }, [props.org_id]);


  // const papers = publicationsData;
  return (
    <div>
      <h5 className="title">Publications over time</h5>
      {isLoading ? (
        <Spinner />
      ) : (
        <ResponsiveContainer height={368}>
          <BarChart data={papers}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="publications" fill="#3986BF" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default PublicationsChart;
