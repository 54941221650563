import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Label,
  LabelList,
  Legend,
} from 'recharts';

const renderCustomizedLabel = (props) => {
  const { content, value, ...rest } = props;
  return <Label {...rest} fontSize="10" fill="#FFFFFF" value={`${value}%`} />;
};

const data = [
  {
    name: 'Service Line',
    'Mobile App Development': 65,
    'AR/VR Development': 15,
    Blockchain: 20,
  },
];

const colors = [
  '#0079C4',
  '#004971',
  '#16717B',
  '#4EB252',
  '#4D8F73',
  '#37A081',
  '#809e8c',
  '#345943',
  '#07507d',
  '#07870c',
  '#034005',
  '#a5cfa7',
  '#414a42',
  '#03ffbc',
  '#07b587',
  '#b6bfbd',
  '#72d2e0',
  '#4097a3',
  '#096f7d',
  '#09373d',
  '#228cbd',
  '#116991',
  '#363e42',
  '#2b5cb5',
  '#0b41a3',
  '#6315b0',
  '#884ec2',
  '#9f77c7',
  '#522a7a',
  '#35730d',
  '#7cb2d6',
];

let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (!active || !tooltip) return null;
  for (const bar of payload)
    if (bar.dataKey === tooltip)
      return (
        <div
          className="custom-tooltip"
          style={{ color: '#666666', border: 'none' }}
        >
          {bar.name}
          <br />
          {bar.value}%
        </div>
      );
  return null;
};

const StackedChart = ({ data }) => {
  const dataLength = Object.keys(data[0]).length;
  return (
    <div style={{ marginBottom: dataLength > 10 ? 80 : 45 }}>
      <ResponsiveContainer height={70} width={'100%'}>
        <BarChart layout="vertical" data={data} stackOffset="expand">
          <XAxis hide type="number" />
          <YAxis
            type="category"
            dataKey="name"
            stroke="#FFFFFF"
            fontSize="12"
            hide
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            height={20}
            align="left"
            wrapperStyle={{ position: 'relative' }}
          />
          {Object.keys(data[0]).map((item, index) => {
            return (
              <Bar
                name={item}
                onMouseOver={() => (tooltip = item)}
                dataKey={item}
                fill={colors[index]}
                stackId="a"
                key={item}
              >
                <LabelList
                  dataKey={item}
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StackedChart;
