import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
const ExpertItems = (props) => {
  const expert = props.expert ?? null;
  return (
    <a href={`/expert/${expert.id}`} style={{ textDecoration: 'none' }}>
      <div className="box-shadow exp-item">
        <div className="row">
          <div className="col-1 exp-avatar">
            <Avatar
              src={expert?.avatar_url}
              variant="square"
              sx={{ width: 96, height: 96 }}
            ></Avatar>
          </div>
          <div className="col-8 exp-info-left">
            <h6 className="exp-name">
              <a href={'/expert/' + expert?.id}>{expert?.name}</a>
            </h6>
            <p className="exp-location">{expert?.current_working_location}</p>
            <ul className="exp-expertise">
              {expert?.expertise &&
                expert?.expertise.map((tech) => <li key={tech}>{tech}</li>)}
            </ul>
          </div>
          <div className="col exp-info-right">
            <ul>
              <li className="row">
                <div className="col-1">
                  <img src="/images/icons/patents.svg" />
                </div>
                <span className="col">I10-index</span>
                <span className="col">{expert?.i10_index}</span>
              </li>
              <li className="row">
                <div className="col-1">
                  <img src="/images/icons/citations.svg" />
                </div>
                <span className="col">Citations</span>
                <span className="col">{expert?.citations}</span>
              </li>
              <li className="row">
                <div className="col-1">
                  <img src="/images/icons/h-index.svg" />
                </div>
                <span className="col">H-index</span>
                <span className="col">{expert?.h_index}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </a>
  );
};

export default ExpertItems;
