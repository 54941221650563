import React from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Circle,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { key } from '../../Map/MyMapComponent';
import Spinner from '../../Spinner';
import './FirmDetail.scss';

const containerStyle = {
  height: '344px',
  width: '100%',
  minHeight: '100%'
};

const FirmMap = ({ firm,latLon }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: key,
  });
  const [map, setMap] = React.useState(null);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div className="row g-5">
      <div className="col-sm-8">
        <GoogleMap
          mapContainerStyle={containerStyle}
          // TODO
          center={{ lat: latLon.lat, lng: latLon.long }}
          zoom={14}
          onUnmount={onUnmount}
        >
          <Marker position={{ lat: latLon.lat, lng: latLon.long }} />
        </GoogleMap>
      </div>
      <div className="col-sm-4">
        <a className="text-decoration-none" href={firm.website} target="_blank">
          <p className="grey-text text-uppercase">visit website</p>
        </a>
        <p className="position-relative contact-text">
          <span className="material-icons icon-note position-absolute bullet">
            location_on
          </span>
          {firm.locations[0].location}
        </p>
        <p className="position-relative contact-text border-bottom pb-5">
          <span className="material-icons icon-note position-absolute bullet">
            call
          </span>
          {firm.locations[0].phone_number ? firm.locations[0].phone_number : 'No data'}
        </p>
        <p className="grey-text text-uppercase mt-5">other locations</p>
        {firm.locations.slice(1).map(item => (
          <div>
            <p className="position-relative contact-text">
              <span className="material-icons icon-note position-absolute bullet">
                location_on
              </span>
              {item.location}
            </p>
            {item.phone_number && (
              <p className="position-relative contact-text">
                <span className="material-icons icon-note position-absolute bullet">
                  call
                </span>
                {item.phone_number}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default FirmMap;
