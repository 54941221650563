import { useState, useEffect } from 'react';
import { Typography, AccordionDetails, Button } from '@mui/material';
import {
  MdApi,
  MdFilterList,
  MdLeaderboard,
  MdApartment,
} from 'react-icons/md';
import { IconContext } from 'react-icons';
import '../../pages/organisation/OrganisationPage.scss';
import { ThemeProvider } from '@mui/material/styles';
import CustomizedHook from '../AutocompleteSearch';
import {
  FilterHeader,
  theme,
  accordionTheme,
  Accordion,
  AccordionSummary,
  FilterAccordionSummary,
} from '../../commons/components-lib';
import { useMediaQuery } from 'react-responsive';
import { services } from '../mock-data';

const ServicesFilter = ({
  relatedServices,
  onServiceChange,
  resetFilter,
  state,
  triggerReset,
}) => {
  useEffect(() => {
    relatedServices.splice(0, relatedServices.length);
    onServiceChange([]);
  }, [resetFilter]);

  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <IconContext.Provider
            value={{ color: '#2770F1', className: 'info-icon', size: 25 }}
          >
            <MdApi />
          </IconContext.Provider>
          <Typography fontSize={16}>Services</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CustomizedHook
          options={services}
          label="Choose services"
          onChange={onServiceChange}
          resetFilter={resetFilter}
          triggerReset={triggerReset}
          state={state}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const LocationsFilter = ({
  regions,
  onRegionsChange,
  resetFilter,
  state,
  triggerReset,
}) => {
  const locations = [
    {
      value: 'Hanoi',
      label: 'Ha Noi',
    },
    {
      value: 'Danang',
      label: 'Da Nang',
    },
    {
      value: 'Ho Chi Minh',
      label: 'Ho Chi Minh',
    },
  ];

  const handleChange = (selectedLabels) => {
    const selectedObj = locations.filter((o) =>
      selectedLabels.includes(o.label),
    );
    onRegionsChange(selectedObj.map((o) => o.value));
  };

  useEffect(() => {
    regions.splice(0, regions.length);
    onRegionsChange([]);
  }, [resetFilter]);

  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <IconContext.Provider
            value={{ color: '#2770F1', className: 'info-icon', size: 25 }}
          >
            <MdApi />
          </IconContext.Provider>
          <Typography fontSize={16}>Regions</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CustomizedHook
          options={locations.map((e) => e.label)}
          label="Choose services"
          onChange={handleChange}
          resetFilter={resetFilter}
          triggerReset={triggerReset}
          state={state}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const FirmPageFilter = ({
  relatedServices,
  regions,
  onServiceChange,
  onRegionsChange,
  onFilterChange,
  onTriggerFilter,
  onSearch,
  state,
}) => {
  const [resetFilter, setResetFilter] = useState(false);
  const [triggerReset, setTriggerReset] = useState(false);
  const isSmaller = useMediaQuery({ maxWidth: 1040 });
  // console.log(relatedServices, regions);

  const handleApplyFilter = () => {
    onFilterChange(true);
    onTriggerFilter();
    onSearch && onSearch();
    setTriggerReset(false);
  };

  const handleResetFilter = () => {
    setResetFilter(!resetFilter);
    onFilterChange(true);
    onTriggerFilter();
    setTriggerReset(true);
  };

  return (
    <div className="filter">
      {isSmaller ? (
        <ThemeProvider theme={accordionTheme}>
          <Accordion>
            <FilterAccordionSummary>
              <FilterHeader />
            </FilterAccordionSummary>
            <AccordionDetails>
              <div className="filter-main">
                <ThemeProvider theme={accordionTheme}>
                  <ServicesFilter
                    relatedServices={relatedServices}
                    onServiceChange={onServiceChange}
                    resetFilter={resetFilter}
                    state={state?.relatedServices}
                    triggerReset={triggerReset}
                  />
                  <LocationsFilter
                    regions={regions}
                    onRegionsChange={onRegionsChange}
                    resetFilter={resetFilter}
                    state={state?.regions}
                    triggerReset={triggerReset}
                  />
                </ThemeProvider>
              </div>
              <div className="filter-btn-container">
                <ThemeProvider theme={theme}>
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<MdFilterList />}
                    fullWidth
                    style={{ marginTop: 10, fontSize: 12, fontWeight: 600 }}
                    onClick={handleApplyFilter}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<MdFilterList />}
                    fullWidth
                    style={{
                      marginTop: 10,
                      fontSize: 12,
                      fontWeight: 600,
                      background: '#ffffff',
                      color: '#3D56B2',
                      borderColor: '#3D56B2',
                    }}
                    onClick={handleResetFilter}
                  >
                    Reset Filter
                  </Button>
                </ThemeProvider>
              </div>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
      ) : (
        <>
          <FilterHeader />
          <div className="filter-main">
            <ThemeProvider theme={accordionTheme}>
              <ServicesFilter
                relatedServices={relatedServices}
                onServiceChange={onServiceChange}
                resetFilter={resetFilter}
                state={state?.relatedServices}
                triggerReset={triggerReset}
              />
              <LocationsFilter
                regions={regions}
                onRegionsChange={onRegionsChange}
                resetFilter={resetFilter}
                state={state?.regions}
                triggerReset={triggerReset}
              />
            </ThemeProvider>
          </div>
          <div className="filter-btn-container">
            <ThemeProvider theme={theme}>
              <Button
                color="primary"
                variant="contained"
                endIcon={<MdFilterList />}
                fullWidth
                style={{ marginTop: 10, fontSize: 12, fontWeight: 600 }}
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
              <Button
                variant="contained"
                endIcon={<MdFilterList />}
                fullWidth
                style={{
                  marginTop: 10,
                  fontSize: 12,
                  fontWeight: 600,
                  background: '#ffffff',
                  color: '#3D56B2',
                  borderColor: '#3D56B2',
                }}
                onClick={handleResetFilter}
              >
                Reset Filter
              </Button>
            </ThemeProvider>
          </div>
        </>
      )}
    </div>
  );
};

export default FirmPageFilter;
