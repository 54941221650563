import '../pages/homepage/Homepage.scss';
import { IconContext } from 'react-icons';
import { MdApartment } from 'react-icons/md';
import stack from '../assets/icons/stack.png';
import chevronDown from '../assets/icons/chevron-down.png';
import { useAsync } from '../commons/hooks/useAsync';
import React from 'react';
import { getTopOrganizations } from '../commons/apis/services/organizations';
import Spinner from './Spinner';

const Organisations = () => {
  const { data, isLoading, isError, isIdle, run } = useAsync();
  React.useEffect(() => {
    run(getTopOrganizations(10));
  }, [run]);
  return (
    <div className="organisations shadow">
      <div className="org-header">
        <div className="d-flex justify-content-center align-items-center gap-1">
          <IconContext.Provider value={{ size: 28 }}>
            <MdApartment />
          </IconContext.Provider>
          Organisations
        </div>
        {/* <div className="center">
          <img className="stack-icon" src={stack} alt="stack" />
          <img src={chevronDown} alt="chevron down" />
        </div> */}
      </div>
      {!(isLoading || isIdle) ? (
        <div className="org-grid">
          {data.data.data.map((org) => (
            <div className="org-block" key={`${org.id}`}>
              <div className="logo">
                <a href={`/organisation/${org.id}`}>
                  <img
                    onError={(e) => {
                      e.currentTarget.src = '/images/avatar/company.jpg';
                    }}
                    src={org.img ? org.img : '/images/avatar/company.jpg'}
                    alt="organizations logo"
                    style={{ width: '100%' }}
                  />
                </a>
              </div>
              <p className="org-name">
                <a href={`/organisation/${org.id}`} className="org-name">
                  {/* {org.name.length > 16
                    ? `${org.name.slice(0, 20)}...`
                    : org.name} */}
                  {org.name}
                </a>
              </p>
              <p className="org-num">
                Vietnamese Experts: <br />
                {org.related_authors}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Organisations;
