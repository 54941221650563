import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Layout/Header';
import FixedHeader from './components/Layout/FixedHeader';
import Footer from './components/Layout/Footer';
import Layout from './pages/Layout';
import NotFountPage from './components/NotFoundPage';
import OrganisationPage from './pages/organisation/OrganisationPage';
import ExpertPage from './pages/expert/ExpertPage';
import TechnologyPage from './pages/technology/TechnologyPage';
import OrganisationDetailPage from './pages/organisation/OrganisationDetailPage';
import ExpertDetailPage from './pages/expert/ExpertDetailPage';
import SearchProvider from './commons/store/SearchProvider';
import TechnologyDetailPage from './pages/technology/TechnologyDetailPage';
import FirmPage from './pages/firm/FirmPage';
import ScrollButton from './components/Layout/ScrollButton';
import FirmDetailPage from './pages/firm/FirmDetailPage';
import AboutUsPage from './pages/aboutUs/AboutUsPage';
import PendingPage from './components/PendingPage';
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

const App = () => {
  const { pathname } = useLocation();
  return (
    <SearchProvider>
      {pathname === '/' ? <Header /> : <FixedHeader />}
      <Routes>
        <Route index path="/" element={<Layout.HomePage />} />
        <Route path="/organisation/:id" element={<OrganisationDetailPage />} />;
        <Route path="/organisation" element={<OrganisationPage />} />
        <Route path="/expert/:id" element={<ExpertDetailPage />} />;
        <Route path="/expert" element={<ExpertPage />} />
        <Route path="/technology/:id" element={<TechnologyDetailPage />} />;
        <Route path="/technology" element={<TechnologyPage />} />
        <Route path="/firm/:id" element={<FirmDetailPage />} />
        <Route path="/firm" element={<FirmPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/project" element={<PendingPage />} />
        <Route path="/association" element={<PendingPage />} />
        <Route path="*" element={<NotFountPage />} />
      </Routes>
      <ScrollButton></ScrollButton>
      <Footer />
    </SearchProvider>
  );
};
export default App;
