import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import TopExperts from '../../components/TopExperts/TopExperts';
import Map from '../../components/Map/Map';
import Organisation from '../../components/Organisations';
import Metadata from '../../components/Metadata';
import WordCloudChart from '../../components/WordCloudChart/WordCloudChart';
import PublicationsChart from '../../components/PublicationsChart';
import './Homepage.scss';
import SearchContext from '../../commons/store/search-context';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Homepage() {
  const [selectedTab, setSelectedTab] = useState(0);
  const searchContext = useContext(SearchContext);
  const navigate = useNavigate();

  const handleOnSearch = (e) => {
    e.preventDefault();
    const search = e.target[0].value;
    searchContext.handleOnSearch({
      name: search.trim(),
      type: 0 ? 'org' : 1 ? 'expert' : 'technology',
    });
    if (selectedTab === 0) {
      navigate('./organisation', { replace: true });
    } else if (selectedTab === 1) {
      navigate('./expert', { replace: true });
    } else {
      navigate('./technology', { replace: true });
    }
  };

  return (
    <React.Fragment>
      <div id="header-content">
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex flex-column text-center">
            <h1 className="text-white">
              AI Marketplace - Connecting AI Vietnamese experts
            </h1>
            <div className="d-flex justify-content-center">
              <h5 className="text-white mt-3">
                Provide insights of the experts and their contributions to
                enhance international research collaboration and improve the
                linkage between research and industry in Vietnam.
              </h5>
            </div>
          </div>
          <div id="search-container" className="container">
            <p className="d-flex justify-content-center">Start your search</p>
            <div className="d-flex justify-content-center list-menu">
              <ul className="navbar-nav d-flex flex-row justify-content-between align-items-center">
                <li className="nav-item font-weight-bold">
                  <a
                    className={`text-white nav-link d-flex justify-content-center ${
                      selectedTab == 0 ? 'nav-active' : ''
                    }`}
                    href="#"
                    onClick={() => setSelectedTab(0)}
                  >
                    Organisation
                  </a>
                </li>
                <li className="nav-item font-weight-bold">
                  <a
                    className={`text-white nav-link d-flex justify-content-center ${
                      selectedTab == 1 ? 'nav-active' : ''
                    }`}
                    href="#"
                    onClick={() => setSelectedTab(1)}
                  >
                    Expert
                  </a>
                </li>
                <li className="nav-item font-weight-bold">
                  <a
                    className={`text-white nav-link d-flex justify-content-center ${
                      selectedTab == 2 ? 'nav-active' : ''
                    }`}
                    href="#"
                    onClick={() => setSelectedTab(2)}
                  >
                    Technology
                  </a>
                </li>
              </ul>
            </div>
            <div id="input-group" className="d-flex justify-content-center">
              <form className="input-group" onSubmit={handleOnSearch}>
                <div
                  id="input-icon"
                  className="d-flex flex-row justify-content-center align-items-center border flex-grow-1"
                >
                  <img className="icon-search" src="/images/icons/Vector.svg" />
                  <input
                    type="text"
                    className="border-0 outline-none h3"
                    placeholder="Enter search keywords here"
                  />
                </div>
                <button
                  type="submit"
                  className="btn input-group-text d-flex justify-content-center"
                  id="text-input-group"
                >
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <img
        style={{ position: 'absolute', right: '15px' }}
        src="/images/background/pattern.svg"
      />
      <div className="container mt-5 mb-5">
        {/* <Map /> */}
        <Metadata />
        <WordCloudChart />
        <PublicationsChart />
        <TopExperts />
        <Organisation />
      </div>
    </React.Fragment>
  );
}
