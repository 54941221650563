import { Link } from 'react-router-dom';
import './index.scss';

const NotFountPage = () => (
  <div id="not-found-page" className="content w-100 d-flex flex-column justify-content-center align-items-center">
    <div className="h1">Something went wrong</div>
    <p className="text-desc">
      The page you are looking for might have been removed had its name changed or is temporarily unavailable.
    </p>
    <img src='/images/background/illustration.svg'/>
    <div className='mt-5'>
      <button type="button" className="btn btn-warning">
        <Link to="/">Back to Homepage</Link>
      </button>
    </div>
  </div>
);

export default NotFountPage;
