import '../../../pages/expert/ExpertPage.scss';
import './ExpertDetail.css';
import React,{ useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getSimilarExpert } from '../../../commons/apis/services/expert.js';
import Spinner from '../../Spinner';

export function SimilarList(props){
  return props.similarExpert?.map((item)=>
    <div key={item.id} className='similar-expert-item d-flex flex-column justify-content-start align-items-center me-5 mb-5'>
      <div className='avatar shadow mt-5 mb-4'>
        <img 
          onError={(e) => {
            e.currentTarget.src = '/images/avatar/default.jpg';
          }}
          className="avatar-img h-100" 
          src={item.avatar_url} 
          alt="" 
        />
      </div>
      <span className='name mb-4'>
          <a href={'/expert/' + item._id}>{item.name}</a>
      </span>
      {item.organisation_name && <p className='organisation text-capitalize mb-4'>{item.organisation_name}</p>}
      {item.h_index && props.sortBy=='h_index' && <span className='h-index mb-4 text-capitalize'>h-index: {item.h_index}</span>}
      {item.citations && props.sortBy=='citations' && <span className='h-index mb-4 text-capitalize'>Citations: {item.citations}</span>}
      {item.i10_index && props.sortBy=='i10_index' && <span className='h-index mb-4 text-capitalize'>I10_index: {item.i10_index}</span>}
    </div>
  )
}

const SimilarExperts = () => {
  const { id } = useParams();
  const [similarExpert, setSimilarExpert] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortBy,setSortBy] = useState('h_index')

  useEffect(() => {
    if (id) {
      const getData = async () => {
        try {
          setLoading(true);
          const { data } = await getSimilarExpert(id);
          setSimilarExpert(data.similar_experts);
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };
      getData();
    }
  }, [id]);

  useEffect(()=>{
    handleOnChangeSortType();
  },[sortBy])

  const handleOnChangeSortType = () =>{
    let sortEx = [...similarExpert];
    sortEx.sort(compare);
    setSimilarExpert(sortEx)
  }

  const compare = (a, b) =>{
    if(sortBy=='citations')  return (a.citations > b.citations) ? -1 : 1;
    else if(sortBy=='h_index') return (a.h_index > b.h_index) ? -1 : 1;
    else return (a.i10_index > b.i10_index) ? -1 : 1;
  }

  return (
    <div className='similar-expert d-flex flex-column mt-4'>
      <div className='d-flex flex-row justify-content-between align-items-start mb-3'>
        <span className='similar-expert-title'>SIMILAR EXPERTS</span>
        <div className="similar-expert-sort d-flex flex-row align-items-center">
          <h5>Sort by</h5>
          <select
            className="border-0 form-select"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="citations" style={{ fontSize: '13px' }}>
              Citations
            </option>
            <option value="i10_index" style={{ fontSize: '13px' }}>
              I10_index
            </option>
            <option value="h_index" style={{ fontSize: '13px' }}>
              H_index
              </option>
          </select>
          <button
            className="btn btn-light d-flex align-items-center border-0 p-0"
            title="Sort"
            disabled
          >
            <span className="material-icons">unfold_more</span>
          </button>
        </div>
      </div>
      <div className='d-flex flex-grow-1 flex-wrap justify-content-center'>
        {
          loading ? <Spinner/> : <SimilarList similarExpert={similarExpert} sortBy={sortBy}/>
        }
      </div>
    </div>
  );
};

export default SimilarExperts;
