export const key = 'AIzaSyDsSBb2sH5X6iFiCAPCwBejLYTKbladgGk';
import { mapStyleDarkMode, mapStyleLightMode } from './configMapStyle';
import React, { useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import StyleMapMode from './StyleMapMode';
import { getLocationInformation } from '../../commons/apis/services/map';
import Spinner from '../Spinner';

export const googleMapsConfig = {
  defaultCenter: { lat: 21.03228, lng: 105.80073 },
  defaultZoom: 5,
  markerIcon: {
    path: '',
    fillOpacity: 0,
  },
  markerHighlightIcon: {
    path: '',
    fillOpacity: 1,
  },
  infoWindowStyle: {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15,
  },
};

const containerStyle = {
  height: '600px',
  with: '400px',
};

const locationInfoCache = {};

function MyMapComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: key,
  });

  const [map, setMap] = React.useState(null);
  const [mode, setMode] = React.useState('light');
  const [locationInfo, setLocationInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getLocationInfo = async (circle) => {
    setIsLoading(true);
    if (!locationInfoCache[circle.id]) {
      let locationInfo;
      if (props.displayMode === 'region') {
        locationInfo = await getLocationInformation(circle.id);
      } else {
        locationInfo = await getLocationInformation(circle.id, 'country');
      }
      setLocationInfo(locationInfo);
      locationInfoCache[circle.id] = locationInfo;
    } else {
      setLocationInfo(locationInfoCache[circle.id]);
    }
    setIsLoading(false);
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(
      googleMapsConfig.defaultCenter,
    );
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  function handleZoomChanged() {
    const zoomLevel = this.getZoom();
    if (zoomLevel <= 5) {
      props.setMode('country');
    } else {
      props.setMode('region');
    }
  }

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={googleMapsConfig.defaultCenter}
        zoom={googleMapsConfig.defaultZoom}
        // onLoad={onLoad}
        onZoomChanged={handleZoomChanged}
        onUnmount={onUnmount}
        options={{
          styles: mode === 'dark' ? mapStyleDarkMode : mapStyleLightMode,
        }}
      >
        {props.circles &&
          props.circles.map((circle, index) => (
            <Marker
              position={circle.center}
              icon={{
                path: google.maps.SymbolPath.CIRCLE,
                scale: 10,
                fillColor: '#9e2a50',
                fillOpacity: 1,
                strokeColor: '#9e2a50',
                strokeOpacity: 1,
              }}
              draggable={false}
              zIndex={circle.infoContent.relatedAuthors + index}
              label={{
                text: `${circle.infoContent.relatedAuthors}`,
                color: '#ffffff',
              }}
              key={circle.id + index}
              onMouseOver={() => {
                props.openCircleInfoWindow(circle.id);
                getLocationInfo(circle);
              }}
              onMouseOut={() => props.closeCircleInfoWindow(circle.id)}
              onClick={() => props.clickCircleInfoWindow(circle.id)}
            >
              {circle.showInfo && (
                <InfoWindow position={circle.center}>
                  {isLoading ? (
                    <Spinner size={14} />
                  ) : (
                    <div style={googleMapsConfig.infoWindowStyle}>
                      <div style={{ color: circle.infoContent.fillColor }}>
                        <span className="info-title">Location: </span>
                        <span className="info-content">
                          {props.displayMode === 'region'
                            ? circle.infoContent.region
                            : circle.infoContent.country}{' '}
                        </span>
                        <br />
                        <span className="info-title">
                          Total related authors:{' '}
                        </span>
                        <span className="info-content">
                          {circle.infoContent.relatedAuthors}{' '}
                        </span>
                        <br />
                        <span className="info-title">Total experts: </span>
                        <span className="info-content">
                          {locationInfo?.total_experts}{' '}
                        </span>
                        <br />
                        <span className="info-title">Top organization: </span>
                        <span className="info-content">
                          {locationInfo?.top_org}{' '}
                        </span>
                        <br />
                        <span className="info-title">Num organizations: </span>
                        <span className="info-content">
                          {locationInfo?.num_org}{' '}
                        </span>
                      </div>
                    </div>
                  )}
                </InfoWindow>
              )}
            </Marker>
          ))}
      </GoogleMap>
      <StyleMapMode onChangeModeStyle={setMode} mode={mode} />
    </div>
  ) : (
    <></>
  );
}

export default React.memo(MyMapComponent);
