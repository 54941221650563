import Top from '../../components/organisation/Top';
import Sidebar from '../../components/organisation/Sidebar';
import Filter from '../../components/organisation/Filter';
import OrganisationDetail from '../../components/organisation/OrganisationDetail';
import { Collapse } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from '../../commons/store/filterThemeProvider';
import { ThemeProvider } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';

const OrganisationDetailPage = () => {
  const [isOpenFilter, setIsOpenFilter] = useState(true);
  const [relatedTech, setRelatedTech] = useState([]);
  const [countries, setCountries] = useState([]);
  const [relatedExperts, setRelatedExperts] = useState([]);
  const [isApplyFilter, setApplyFilter] = useState(false);
  const [handleTriggerFilter, setTriggerFilter] = useState(false);
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const isSmaller = useMediaQuery({ maxWidth: 1040 });

  const handleSearch = () => {
    navigate('/organisation', {
      replace: true,
      state: {
        relatedExperts,
        relatedTech,
        countries,
      },
    });
  };

  return (
    <>
      <Top onNameChange={setName} onSearch={handleSearch} />
      <div className="organisation-content">
        <Sidebar isOpen={isOpenFilter} onSelect={setIsOpenFilter} />
        {isSmaller ? (
          <Filter
            onTechChange={setRelatedTech}
            onCountryChange={setCountries}
            onExpertChange={setRelatedExperts}
            onFilterChange={setApplyFilter}
            relatedExperts={relatedExperts}
            onTriggerFilter={() => {
              setTriggerFilter(!handleTriggerFilter);
            }}
            onSearch={handleSearch}
          />
        ) : (
          <ThemeProvider theme={theme}>
            <Collapse in={isOpenFilter} orientation="horizontal">
              <Filter
                onTechChange={setRelatedTech}
                onCountryChange={setCountries}
                onExpertChange={setRelatedExperts}
                onFilterChange={setApplyFilter}
                relatedExperts={relatedExperts}
                onTriggerFilter={() => {
                  setTriggerFilter(!handleTriggerFilter);
                }}
                onSearch={handleSearch}
              />
            </Collapse>
          </ThemeProvider>
        )}
        <OrganisationDetail />
      </div>
    </>
  );
};

export default OrganisationDetailPage;
