import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

const colors = [
  '#0079C4',
  '#004971',
  '#16717B',
  '#4EB252',
  '#4D8F73',
  '#37A081',
  '#72d2e0',
  '#4097a3',
  '#096f7d',
  '#09373d',
  '#228cbd',
  '#116991',
  '#363e42',
];

const StackChart = (props) => {
  const dataChart = props.dataChart;

  return (
    <div className="w-100">
      {dataChart.length > 0 && (
        <ResponsiveContainer height={40} width={'100%'}>
          <BarChart layout="vertical" data={dataChart} stackOffset="expand">
            <XAxis hide type="number" />
            <YAxis
              type="category"
              dataKey="name"
              stroke="#FFFFFF"
              fontSize="12"
              hide
            />
            <Tooltip />
            {Object.keys(dataChart[0]).map((item, index) => {
              return (
                <Bar dataKey={item} fill={colors[index]} stackId="a"></Bar>
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default StackChart;
