import SyncLoader from 'react-spinners/SyncLoader';
import './index.scss';

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: '#3986BF',
};

const Spinner = ({ loading, size = 20, ...props }) => (
  <div className="spinner-container">
    <SyncLoader
      color={'#3986BF'}
      loading={loading}
      cssOverride={override}
      size={size}
      {...props}
    />
  </div>
);

export default Spinner;
