import Top from '../../components/organisation/Top';
import Sidebar from '../../components/organisation/Sidebar';
import { Collapse, ThemeProvider } from '@mui/material';
import TechnologyPageFilter from '../../components/technology/TechnologyPageFilter';
import TechnologyDetail from '../../components/technology/TechnologyDetail';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from '../../commons/store/filterThemeProvider';
import { useMediaQuery } from 'react-responsive';

const TechnologyDetailPage = () => {
  const [isOpenFilter, setIsOpenFilter] = useState(true);
  const [relatedExperts, setRelatedExperts] = useState([]);
  const [isApplyFilter, setApplyFilter] = useState(false);
  const [handleTriggerFilter, setTriggerFilter] = useState(false);
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const isSmaller = useMediaQuery({ maxWidth: 1040 });

  const handleSearch = () => {
    navigate('/technology', { replace: true, state: { relatedExperts } });
  };

  return (
    <>
      <Top onNameChange={setName} onSearch={handleSearch} />
      <div className="organisation-content">
        <Sidebar isOpen={isOpenFilter} onSelect={setIsOpenFilter} />
        {isSmaller ? (
          <TechnologyPageFilter
            onExpertChange={setRelatedExperts}
            onFilterChange={setApplyFilter}
            relatedExperts={relatedExperts}
            onTriggerFilter={() => {
              setTriggerFilter(!handleTriggerFilter);
            }}
            onSearch={handleSearch}
          />
        ) : (
          <ThemeProvider theme={theme}>
            <Collapse in={isOpenFilter} orientation="horizontal">
              <TechnologyPageFilter
                onExpertChange={setRelatedExperts}
                onFilterChange={setApplyFilter}
                relatedExperts={relatedExperts}
                onTriggerFilter={() => {
                  setTriggerFilter(!handleTriggerFilter);
                }}
                onSearch={handleSearch}
              />
            </Collapse>
          </ThemeProvider>
        )}

        <TechnologyDetail />
      </div>
    </>
  );
};

export default TechnologyDetailPage;
