import { httpService } from '../services';
import { API } from '../endPoint';

const getListOrgs = async ({ search, size, page, deep,locations, services }) => {
  let url = API.GET_LIST_FIRMS;
  if (search) {
    url += `search=${encodeURIComponent(`${search}`)}&`;
  }
  if (size) {
    url += `size=${encodeURIComponent(`${size}`)}&`;
  }

  if (page) {
    url += `page=${encodeURIComponent(`${page}`)}&`;
  }

  if (deep) {
    url += `deep=${encodeURIComponent(`${deep}`)}&`;
  }
  if (locations && locations.length > 0) {
    locations.forEach((item) => {
      url += `locations=${encodeURIComponent(item)}&`;
    });
  }
  if (services && services.length > 0) {
    services.forEach((item) => {
      url += `services=${encodeURIComponent(item)}&`;
    });
  }

  url = url.replace(/[?&]$/, '');

  const response = await httpService.get(url);

  if (response.code !== 0) {
    return null;
  }
  return response;
};

const getFirmById = async (id, deep = true) => {
  let url = `${API.GET_FIRM_BY_ID}${id}?deep=${deep}`;
  const response = await httpService.get(url);

  if (response.code !== 0) {
    return null;
  }
  return response;
};

const getReview = async (id) => {
  let url = `${API.GET_FIRM_BY_ID}${id}/reviews`;
  const response = await httpService.get(url);

  if (response.code !== 0) {
    return null;
  }
  return response;
};

export { getListOrgs, getFirmById, getReview };
