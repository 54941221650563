import { useState } from 'react';
import SearchContext from './search-context';

const defaultSearchState = {
  name: '',
  type: 'org',
};
const SearchProvider = (props) => {
  const [searchState, setSearchState] = useState(defaultSearchState);
  const searchContext = {
    name: searchState.name,
    type: searchState.type,
    handleOnSearch: setSearchState,
  };

  return (
    <SearchContext.Provider value={searchContext}>
          {props.children}
    </SearchContext.Provider>
  );
};

export default SearchProvider
