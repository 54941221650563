import { createTheme } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { IconContext } from 'react-icons';
import {
  AiOutlineInfoCircle,
  AiOutlineRight,
  AiTwotoneFilter,
} from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@mui/material/styles';
import '../pages/organisation/OrganisationPage.scss';

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#3D56B2',
      darker: '#053e85',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

const accordionTheme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  width: '100%',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <IconContext.Provider
        value={{ color: '#2770F1', className: 'info-icon', size: 18 }}
      >
        <AiOutlineRight />
      </IconContext.Provider>
    }
    {...props}
  />
))(() => ({
  backgroundColor: '#fff',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {},
}));

const FilterAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <IconContext.Provider
        value={{ color: '#2770F1', className: 'info-icon', size: 18 }}
      >
        <AiOutlineRight />
      </IconContext.Provider>
    }
    {...props}
  />
))(() => ({
  borderRadius: '8px',
  backgroundColor: '#dff3ff',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    padding: 0,
  },
}));

const FilterHeader = () => {
  const isSmaller = useMediaQuery({ maxWidth: 1040 });

  return (
    <div className="filter-header">
      <div
        style={{
          fontSize: '14px',
          fontWeightL: 500,
          textTransform: 'uppercase',
        }}
        className="d-flex align-items-center"
      >
        {isSmaller ? (
          <span
            className="material-icons me-2 icon-note"
            style={{ color: '#27c1f1' }}
          >
            filter_alt
          </span>
        ) : (
          ''
        )}
        Filter
        <IconContext.Provider
          value={{ color: '#EEBD40', className: 'info-icon', size: 18 }}
        >
          <AiOutlineInfoCircle />
        </IconContext.Provider>
      </div>
    </div>
  );
};

export {
  theme,
  accordionTheme,
  Accordion,
  AccordionSummary,
  FilterHeader,
  FilterAccordionSummary,
};
