import '../../pages/organisation/OrganisationPage.scss';
import { useState, useEffect, useContext } from 'react';
import { getSearchExpert } from '../../commons/apis/services/expert';
import Spinner from '../Spinner';
import Pagination from '@mui/material/Pagination';
import './FirmContent.scss';
import { Rating, Tooltip } from '@mui/material';

import SearchContext from '../../commons/store/search-context';
import StackChart from './StackChart';
import { getListOrgs } from '../../commons/apis/services/firm';

export const formatDataChart = (data) => {
  let dataArray = [];
  let dataObject = {};
  let others = 0;
  let items = []
  data.name.forEach((element, index) => {
    const percentage = +data.percentage[index].replace('%', '');
    if (percentage <= 2) {
      others += percentage;
    } else {
      dataObject[element] = percentage;
      items.push({
          name: element,
          value: percentage
      })
    }
  });
  if (others > 0) {
    dataObject.Others = others;
    items.push(
        {
            name: 'Others',
            value: others
        }
    )
  }
  dataArray.push(dataObject);
  items.sort(function (a, b) {
    return b.value - a.value;
  });
  items = items.slice(0,3);
  let sum = 0;
  items.map(function (item) {
    sum += item.value;
  });
  if(sum < 100){
      items.push({
          name: 'Others',
          value: 100 - sum
      })
  }
  return {
      objType: dataArray,
      arrayType: items
  }
};

const FirmContent = (props) => {
  const [sortBy, setSortBy] = useState('Related Experts');
  const [isSortByDesc, setSortByDesc] = useState(true);
  const [loadingFirms, setLoadingFirms] = useState(false);
  const [firmsData, setFirmsData] = useState([]);
  const [page, setPage] = useState(1);
  const [firmsNumber, setFirmsNumber] = useState(533);
  const searchContext = useContext(SearchContext);

  const fetchData = async () => {
    try {
      setLoadingFirms(true);
      const { data: dataObject } = await getListOrgs({
        search: searchContext.name,
        size: 10,
        page: page,
        deep: true,
        locations: props.state ? props.state.regions : props.regions,
        services: props.state
          ? props.state.relatedServices
          : props.relatedServices,
      });
      setFirmsData(dataObject.items);
      setFirmsNumber(dataObject.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingFirms(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, props.handleTriggerFilter, searchContext.name]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleOnChangeSortType = () => {
    setSortByDesc(!isSortByDesc);
  };

  const renderListItemFirms = () => {
    return firmsData?.map((item, index) => (
      <div
        key={item.id}
        id="item-firms"
        className="form-control d-flex flex-row align-items-start w-100 flex-grow-1 mb-4"
      >
        <div className="infor d-flex flex-row align-items-between justify-content-between w-100">
          <div className="d-flex flex-column w-100" style={{ padding: '20px' }}>
            <div className="d-flex flex-row w-100">
              <a href={'/firm/' + item.id} className="img shadow rounded">
                <img
                  src={item.logo ? item.logo : '/images/avatar/company.jpg'}
                />
              </a>
              <div className="d-flex flex-column align-items-between justify-content-start w-100 ms-3">
                <a
                  href={'/firm/' + item.id}
                  // target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  <h3>{item.name}</h3>
                </a>
                {item.best_review && (
                  <label className="slogan">
                    {item.best_review.review.review}
                  </label>
                )}
                {item.summary_description.agg_rating && (
                  <div
                    className="d-flex flex-row align-items-center justify-content-between mt-1"
                    style={{ width: '190px' }}
                  >
                    <label className="rating-text">
                      {item.summary_description.agg_rating}
                    </label>
                    <Rating
                      name="half-rating"
                      defaultValue={item.summary_description.agg_rating}
                      readOnly
                    />
                    <label className="rating-viewer text-capitalize">
                      {item.summary_description.total_rating}
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-row w-100 h-100 mt-5 firms-footer">
              <div className="d-flex flex-column justify-content-between align-items-start pt-5 pb-3 infor-firms">
                <div className="d-flex flex-row align-items-center mb-2">
                  <img
                    src="/images/icons/MoneyFirm.svg"
                    style={{ width: '15px' }}
                  />
                  <label className="money-text mx-3">
                    {item.summary_description.min_project_size.toLocaleString()}
                  </label>
                </div>
                <div className="d-flex flex-row align-items-center mb-2">
                  <img
                    src="/images/icons/ClockFirm.svg"
                    style={{ width: '15px' }}
                  />
                  <label className="money-text mx-3">
                    {item.summary_description.hourly_rate}
                  </label>
                </div>
                <div className="d-flex flex-row align-items-center mb-2">
                  <img
                    src="/images/icons/PeopleFirm.svg"
                    style={{ width: '15px' }}
                  />
                  <label className="money-text mx-3">
                    {item.summary_description.nums_employee}
                  </label>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <img
                    src="/images/icons/LocationFirm.svg"
                    style={{ width: '15px' }}
                  />
                  <label className="money-text mx-3">
                    {item.locations[0].location}
                  </label>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between align-items-start pt-5 pb-3 chart-firms">
                <label className="chart-firms-text ms-1">Service Focus</label>
                <StackChart
                  dataChart={formatDataChart(item.service_focus.service_lines).objType}
                />
                {
                  formatDataChart(item.service_focus.service_lines).arrayType.map((item) => {
                    return (
                      <label className="chart-firms-desc ms-1">
                        {`${item.value}% ${item.name}`}
                      </label>
                    );
                })}
              </div>
              <div
                className="d-flex flex-column justify-content-start align-items-start pt-5 pb-3 px-4 text-firms"
                style={{ width: '250px' }}
              >
                <label
                  className="text-firms-content mx-1 mb-2"
                  title={item.summary_description.description}
                >
                  "
                  {item.summary_description.description.length > 200
                    ? `${item.summary_description.description.slice(0, 200)}...`
                    : item.summary_description.description}
                  "
                </label>
                <div className="d-flex flex-row flex-grow-1 align-items-center">
                  <label className="text-firms-content mx-1">
                    {item.summary_description.founded_year}
                  </label>
                  <span
                    className="material-icons"
                    style={{ color: 'red', fontSize: '17px' }}
                  >
                    check_circle_outline
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-column align-items-between view-firm"
            style={{ padding: '20px' }}
          >
            <a
              href={item.website}
              // target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <div
                className="box-view border-0"
                style={{ background: '#42BBFF' }}
              >
                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                  <label
                    className="box-view-label"
                    style={{ color: 'white', cursor: 'pointer' }}
                  >
                    Visit website
                  </label>
                  <span
                    className="material-icons box-view-icon"
                    style={{ color: 'white' }}
                  >
                    language
                  </span>
                </div>
              </div>
            </a>
            <a
              href={'/firm/' + item.id}
              // target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <div className="box-view border-0" style={{ background: 'none' }}>
                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                  <label
                    className="box-view-label"
                    style={{ color: '#42BBFF', cursor: 'pointer' }}
                  >
                    View Profile
                  </label>
                  <span
                    className="material-icons box-view-icon"
                    style={{ color: '#42BBFF' }}
                  >
                    info_outline
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="content-firms h-100">
      <div className="d-flex flex-column w-100">
        <h3>Firm Search Results</h3>
        <div className="bar d-flex w-100 flex-row border justify-content-between align-items-center form-control p-0 mt-3">
          <button className="btn btn-light px-0 border-start-0 border-top-0 border-bottom-0">
            <span className="material-icons">chevron_left</span>
          </button>
          <div className="related d-flex flex-row flex-grow-1 py-3 justify-content-start ps-4">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h6>Firms</h6>
              <label>{firmsNumber.toLocaleString()}</label>
            </div>
          </div>
          <button className="btn btn-light px-0 border-end-0 border-top-0 border-bottom-0">
            <span className="material-icons">chevron_right</span>
          </button>
        </div>
        <div className="sort d-flex flex-row align-items-center my-4">
          {/* <h5>Sort by</h5>
          <select
            className="border-0 form-select"
            value={sortBy}
          >
            <option value="Related Experts">Related Experts</option>
            <option value="Related Experts1">Related Experts1</option>
            <option value="Related Experts2">Related Experts2</option>
          </select>
          <button
            className="btn btn-light d-flex align-items-center border-0 p-0"
            title="Sort"
            onClick={handleOnChangeSortType}
          >
            <span className="material-icons">unfold_more</span>
          </button> */}
        </div>
        <div className="w-100">
          {loadingFirms ? <Spinner /> : renderListItemFirms()}
        </div>
        <div className="d-flex justify-content-center">
          <Pagination
            count={Math.ceil(firmsNumber / 10)}
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
};

export default FirmContent;
