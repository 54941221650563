import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { GrHomeRounded } from 'react-icons/gr';
import '../../pages/organisation/OrganisationPage.scss';
import { useState } from 'react';
import SearchContext from '../../commons/store/search-context';
import { useContext } from 'react';

const Top = (props) => {
  const searchContext = useContext(SearchContext);
  const [name, setName] = useState(searchContext.name);
  const handleOnChange = (event) => {
    setName(event.target.value);
    props.onNameChange(event.target.value.trim());
  };

  const handleOnSearch = (e) => {
    e.preventDefault();
    searchContext.handleOnSearch({
      type: searchContext.type,
      name: name.trim(),
    });
    if (props.onSearch) {
      props.onSearch();
    }
  };

  return (
    <div className="top d-flex justify-content-between align-items-center">
      <Breadcrumbs aria-label="breadcrumb" className="breadcrump" style={{ marginLeft: "10px",marginRight: "10px"}}>
        <Link to="/">
          <GrHomeRounded size={16} />
        </Link>
      </Breadcrumbs>
      <form
        className="input-group-org d-flex flex-row align-items-center"
        onSubmit={handleOnSearch}
      >
        <div className="input-icon-org d-flex flex-row justify-content-start align-items-center border flex-grow-1">
          <img className="icon-search-org" src="/images/icons/Vector.svg" />
          <input
            type="text"
            className="border-0 outline-none h4"
            placeholder="Enter search keywords here"
            value={name}
            onChange={handleOnChange}
          />
        </div>
        <button
          type="submit"
          className="btn input-group-text-org d-flex justify-content-center align-items-center"
        >
          Search
        </button>
      </form>
    </div>
  );
};
export default Top;
