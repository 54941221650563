import React, { useEffect } from 'react';
import Top from '../../components/organisation/Top';
import Sidebar from '../../components/organisation/Sidebar';
import Filter from '../../components/organisation/Filter';
import OrganisationContent from '../../components/organisation/OrganisationContent';
import { Collapse, ThemeProvider } from '@mui/material';
import SearchContext from '../../commons/store/search-context';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import theme from '../../commons/store/filterThemeProvider';

const OrganisationPage = () => {
  const [isOpenFilter, setIsOpenFilter] = React.useState(true);
  const [relatedTech, setRelatedTech] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [relatedExperts, setRelatedExperts] = React.useState([]);
  const [isApplyFilter, setApplyFilter] = React.useState(false);
  const [triggerFilter, setTriggerFilter] = React.useState(false);
  const [name, setName] = React.useState('');
  const [state, setState] = React.useState(useLocation().state);
  const searchContext = useContext(SearchContext);
  const isSmaller = useMediaQuery({ maxWidth: 1040 });

  const handleTriggerFilter = () => {
    let oldName = searchContext.name;
    searchContext.handleOnSearch({
      type: 'org',
      name: name,
    });
    setState(null);
    if (oldName === name) {
      setTriggerFilter(!triggerFilter);
    }
  };

  useEffect(() => {
    if (state) {
      setRelatedExperts(state.relatedExperts);
    }
  }, []);

  return (
    <>
      <Top onNameChange={setName} />
      <div className="organisation-content">
        <Sidebar isOpen={isOpenFilter} onSelect={setIsOpenFilter} />
        {isSmaller ? (
          <Filter
            onTechChange={setRelatedTech}
            onCountryChange={setCountries}
            onExpertChange={setRelatedExperts}
            onFilterChange={setApplyFilter}
            relatedExperts={relatedExperts}
            onTriggerFilter={handleTriggerFilter}
            state={state}
          />
        ) : (
          <ThemeProvider theme={theme}>
            <Collapse in={isOpenFilter} orientation="horizontal">
              <Filter
                onTechChange={setRelatedTech}
                onCountryChange={setCountries}
                onExpertChange={setRelatedExperts}
                onFilterChange={setApplyFilter}
                relatedExperts={relatedExperts}
                onTriggerFilter={handleTriggerFilter}
                state={state}
              />
            </Collapse>
          </ThemeProvider>
        )}

        <OrganisationContent
          name={name}
          relatedTech={relatedTech}
          countries={countries}
          relatedExperts={relatedExperts}
          isApplyFilter={isApplyFilter}
          handleTriggerFilter={triggerFilter}
          state={state}
        />
      </div>
    </>
  );
};

export default OrganisationPage;
