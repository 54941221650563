import { useMediaQuery } from 'react-responsive';
import './AboutUsPage.css';
import data from './data';

const AboutUsPage = () => {
  const isSmaller = useMediaQuery({ maxWidth: 540 });
  return (
    <div id="about-us">
      <div className="header">
        <h1>About Us</h1>
      </div>
      <div className="container">
        <div
          id="about-story"
          className="d-flex justify-content-between align-items-center mt-5"
        >
          <div className="flex-item">
            <h2>VNconnect’s Story</h2>
            <p className="mt-4">
              VNconnect is developed as part of the project “Vietnam AI
              marketplace.” This is a collaboration under the A4I program
              framework between the Commonwealth Scientific and Industrial
              Research Organisation (CSIRO), the Ministry of Science and
              Technology, and other Vietnamese and Australian research
              institutions, including Griffith University, Hanoi University of
              Science and Technology, and the Institute of Research in
              Economics, Environment and Data Science in Vietnam. The project is
              led by CSIRO’s Data61.
            </p>
          </div>
          <div className="flex-item" style={{ width: 'fit-content' }}>
            <img src="/images/about-us/standup-meeting.svg" />
          </div>
        </div>
        <div id="about-function" className="mt-5">
          <h2>How Do We Help</h2>
          <div className="d-flex justify-content-between">
            <div className="flex-item">
              <div>
                <img src="/images/about-us/problem-solving-pana.svg" />
              </div>
              <div className="mt-5">
                <p className="sub-title">Your Challenges</p>
                <p className="text-focus">
                  Tech is disrupting every industry and business. How to find a
                  tech vendor or expert with the capabilities and experience to
                  tackle your project?
                </p>
                <ul className="text-justify">
                  <li>
                    <p>
                      On the demand side: It remains difficult to access a
                      supply of high-quality solutions amidst an increasingly
                      fragmented and turbulent technology landscapes. How to
                      navigate to a trusted source to find relevant tech
                      solutions, all in one place?
                    </p>
                  </li>
                  <li>
                    <p>
                      On the supply side: The domestic supply of technology in
                      Vietnam is developing though still limited. Emerging
                      domestic tech vendors, mostly small and medium
                      enterprises, and leading-edge research experts from
                      domestic universities often struggle to connect on
                      necessary businesses.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex-item">
              <div>
                <img src="/images/about-us/online-tech-talks-bro.svg" />
              </div>
              <div className="mt-5">
                <p className="sub-title">Our Solutions</p>
                <p className="text-focus">
                  VNconnect provides the most comprehensive data on Vietnamese
                  AI experts and organisations globally, enable smarter
                  decisions about tech.
                </p>
                <ul className="text-justify">
                  <li>
                    The platform unifies the tech demand and supply by acting as
                    a digital playground to connect the many Vietnamese research
                    institutions, experts, and businesses domestic and oversea.
                  </li>
                  <li>
                    The platform arms businesses with trusted data, insights,
                    reviews and analyses they need in order to turbocharge
                    digital transformation and tackle challenges with
                    confidence.
                  </li>
                  <li>
                    The platform is a go-to place to showcase technology and
                    innovation of tech suppliers and experts.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex-item">
              <div>
                <img src="/images/about-us/standup-meeting-rafiki.svg" />
              </div>
              <div className="mt-5">
                <p className="sub-title">A Single Source Of Truth On Tech </p>
                <p className="text-focus">
                  VNconnect implements various functionalities to the users for
                  a all-in-one source of truth and insights of technology
                  innovation in Vietnam.
                </p>
                <ul className="list-tech text-justify">
                  <li>
                    A technology search engine: search through the market by
                    geography, organisations, experts and more.
                  </li>
                  <li>
                    In-depth reviews and insights about tech solutions: build
                    confidence in your decision making.
                  </li>
                  <li>
                    Connect with companies and experts in a single click:
                    powerful partnership to fuel your tech transformation.
                  </li>
                  <li>
                    Market analysis and visualisations of AI industry in Vietnam
                    using real-time data: know where the next opportunity is.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="about-team" className="mt-5">
          <h2>Our Team</h2>
          <div className="mt-5" id="lead-team">
            <h3>Our Leading Team</h3>
            <div className="d-flex justify-content-between mt-4">
              {data.leadingTeam.map((item) => (
                <div className="flex-item">
                  <div className="member-avatar mb-4">
                    <img src={item.avatar}></img>
                  </div>
                  <div>
                    <p className="member-name">{item.name}</p>
                    <p className="profile-title">{item.title}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-5" id="project-team">
            <h3>Project Team</h3>
            {data.projectTeam.map((item) => (
              <div className="d-flex justify-content-between border-end">
                <div className="flex-item">
                  <div className={`member-avatar ${isSmaller ? 'mb-4' : ''}`}>
                    <img src={item.avatar}></img>
                  </div>
                </div>
                <div className="flex-item">
                  <p className="member-name">{item.name}</p>
                  <p className="profile-title">{item.title}</p>
                  <p className="bio">{item.bio}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-5" id="project-org">
            <div className="row justify-content-center gy-5">
              {/* First 3 org logo */}
              {data.org
                .filter((_, i) => i <= 2)
                .map((item, i) => (
                  <div className="col-3 d-flex justify-content-center">
                    <img height={95} src={item} />
                  </div>
                ))}
              {/* The rest */}
              <div className="col-4 d-flex justify-content-center">
                <img height={85} src={data.org[3]} />
              </div>
              <div className="col-1 d-flex justify-content-center">
                <img height={85} src={data.org[4]} />
              </div>
              <div className="col-3 d-flex justify-content-center">
                <img height={80} src={data.org[5]} />
              </div>
              <div className="col-3 d-flex justify-content-center">
                <img height={80} src={data.org[6]} />
              </div>
              <div className="col-1 d-flex justify-content-center">
                <img height={85} src={data.org[7]} />
              </div>
            </div>
          </div>
          <div className="mt-5" id="partner-team">
            <h3>Partner With Us</h3>
            <div className="d-flex justify-content-between align-items-start mt-4">
              {data.partners.map((item) => (
                <div className="flex-item">
                  <div className="member-avatar mb-4">
                    <img src={item.avatar}></img>
                  </div>
                  <div>
                    <p className="member-name">{item.name}</p>
                    <p className="profile-title">{item.title}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div id="contact" className="mt-5">
        <div id="banner">
          <p>
            To build a meaningful partnership and create values, email us at{' '}
            <br /> <strong>vnconnect.info@gmail.com</strong>
          </p>
        </div>
      </div>
    </div>
  );
};
export default AboutUsPage;
