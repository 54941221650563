import '../pages/homepage/Homepage.scss';
import React, { useEffect, useState } from 'react';
import { data } from './mock-data';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import { getSearchExpert } from '../commons/apis/services/expert.js';
// const FileDownload = require('js-file-download');
import FileDownload from 'js-file-download';
import axios from 'axios';
import { getSearchOrganizations } from '../commons/apis/services/organizations.js';
import Spinner from './Spinner.js';

const Metadata = () => {
  const [dataExperts, setDataTopExperts] = useState(0);
  const [countTech, setCountTech] = useState(0);
  const [countOrg, setCountOrg] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: dataObject } = await getSearchExpert({
          name: '',
          size: 5,
          page: 1,
        });
        setCountTech(dataObject.data.count_tech_related);
        setDataTopExperts(dataObject.count);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryObj = {
          name: '',
          size: 5,
          page: 1,
        };
        const { data: dataObject } = await getSearchOrganizations(queryObj);
        setCountOrg(dataObject.count);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    fetchData();
  }, []);


  return (
    <div className="grid-2-cols">
      <div className="metadata-diagram">
        <div className="ball_wrap">
          <div className="expert-ball ball-text">
            <span className="ball-stats">{dataExperts > 0 ? dataExperts : ''}</span> Experts
          </div>
          <div className="publication-ball ball-text">
            <span className="ball-stats">113124</span>Publications
          </div>
          <div className="org-ball ball-text">
            <span className="ball-stats">{countOrg > 0 ? countOrg : ''}</span>Organizations{' '}
          </div>
          <div className="tech-ball ball-text">
            <span className="ball-stats">{countTech > 0 ? countTech : ''}</span>Technologies{' '}
          </div>
          <div className="patent-ball ball-text">
            <span className="ball-stats">4619</span>Patents{' '}
          </div>
        </div>
      </div>
      <div>
        <p className="title">Up-to-date metadata</p>
        <div className="description">
          <p>
            Store and analyze real-time digital records of both domestic and oversea
            Vietnamese AI experts; and their related information such as patents,
            scholarly works, institutions, innovative technologies and
            collaborations.
          </p>
          {/* <a href={process.env.PUBLIC_URL + "/data/csiro_final_data_v4.xlsx"} download="csiro_final_data_v4"> */}
          {/* <a href={process.env.PUBLIC_URL + "/data/csiro_final_data_v4.xlsx"} download="csiro_final_data_v4"> */}
          <Button startIcon={<DownloadIcon />} style={{ padding: 0, fontSize: 'inherit' }} onClick={() => {
            axios({
              url: 'https://api-dev.vnconnect.info/expert/export-excel',
              method: 'GET',
              responseType: 'blob', // Important
            }).then((response) => {
              FileDownload(response.data, 'report.xlsx');
            });

          }}>
            Download Data
          </Button>
          {/* </a> */}
        </div>
        <a href="/expert">
          <button type="button" className="btn-secondary">
            Learn More
          </button>
        </a>
      </div>
    </div>
  );
};
export default Metadata;
