import { useEffect, useState } from 'react';
import ReactWordcloud from 'react-wordcloud';
import { useNavigate } from 'react-router-dom';

import { getRelatedExpert } from '../../commons/apis/services/technologies';
import Spinner from '../Spinner';

const options = {
  colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
  enableTooltip: false,
  deterministic: false,
  fontFamily: 'impact',
  fontSizes: [15, 60],
  fontStyle: 'normal',
  fontWeight: 'normal',
  padding: 2,
  rotations: 3,
  rotationAngles: [0, 0],
  scale: 'sqrt',
  spiral: 'archimedean',
  transitionDuration: 1000,
};

const WordCloudChart = () => {
  const [techList, setTechList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const callbacks = {
    onWordClick: (word) => {
      navigate(`/technology/${word.id}`, {
        replace: false,
      });
    },
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const res = await getRelatedExpert(1, 70);
      var techList = res.map((tech) => {
        return {
          text: tech.name,
          value: tech.related_authors,
          id: tech._id,
        };
      });
      setTechList(techList);
      setIsLoading(false);
    };
    fetchData();
  }, []);
  return (
    <div className="grid-2-cols grid-3-7">
      <div>
        <h2 className="title">Innovative Technologies</h2>
        <p className="description">
          Understand, monitor, and predict the flow of the most innovative
          technologies in AI and computer science through analysing the dynamic
          research trends of the experts.
        </p>
        <a href="/technology">
          <button type="button" className="btn-secondary">
            Learn More
          </button>
        </a>
      </div>
      <div>
        {isLoading ? (
          <Spinner size={15} />
        ) : (
          <ReactWordcloud
            words={techList}
            options={options}
            callbacks={callbacks}
          />
        )}
      </div>
    </div>
  );
};
export default WordCloudChart;
