import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    // Name of the component
    MuiCollapse: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: '#fff',
        },
      },
    },
  },
});

export default theme;
