const data = {
  leadingTeam: [
    {
      name: 'Dr. Bui The Duy',
      title: 'Vice Minister  -  Ministry of Science and Technology',
      avatar: 'https://minio.hisoft.com.vn/csiro/image/VM_duy1.jpg',
    },
    {
      name: 'Assoc. Prof. Huynh Quyet Thang',
      title: 'Director – University of Science and Technology',
      avatar:
        'https://scholar.googleusercontent.com/citations?view_op=view_photo&user=Vz24y3sAAAAJ&citpid=1',
    },
  ],
  projectTeam: [
    {
      name: 'Dr. Pham Thu Hien',
      title: 'Research Scientist, Data61 Insights',
      avatar: 'https://minio.hisoft.com.vn/csiro/image/Hien-Pham.png',
      bio: 'Hien Pham is a research scientist in Data 61 — CSIRO. Pham holds a Ph.D. in economics from the University of Queensland (2016) and tertiary qualifications in International Economics from the National Graduate Institute for Policy Studies (GRIPS) in Japan (2008). Before joining CSIRO, Hien worked as a lecturer at the UQ School of Economics (2015-2016), specializing in macroeconomics and international trade. Pham had 8 years of working experience in the public sector, focusing on Official Development Assistance (ODA) management and strategy development. Pham also worked as a representative of Vietnam in different working groups within the framework of ASEAN and APEC. Her Ph.D. focused on investigating the relationship between the distribution of firm size and production efficiency, as well as the nature of technology across firms.',
    },
    {
      name: 'Dr. Henry Nguyen',
      title: 'Senior Lecturer, Griffith University',
      avatar: 'https://minio.hisoft.com.vn/csiro/image/Henry_full.jpg',
      bio: 'Dr. Quoc Viet Hung (Henry) Nguyen is a senior lecturer and an ARC DECRA Fellow (Australia Discovery Early Career Researcher Award) at Griffith University. His research focuses on Text Mining, Natural Language Processing, Data Integration, Data Quality, Information Retrieval, Recommender Systems, Machine Learning, and Big Data Visualization, with special emphasis on web data and social data. He has published 100+ papers (50+ A* ranking, 30+ A ranking) and served as senior program committee for several A* conferences such as IJCAI, AAAI, TheWebConf, CIKM, ICONIP, etc. He is the director of the IoT Cluster and the Big Data Visualisation Lab at Griffith University.',
    },
    {
      name: 'Dr. Phi Le Nguyen',
      title:
        'Lecturer, Managing director of BK.AI – Hanoi University of Science    n and Technology (HUST)',
      avatar: 'https://minio.hisoft.com.vn/csiro/image/Le_pic2.png',
      bio: 'Phi Le Nguyen received her B.E. and M.S. degrees from the University of Tokyo in 2007 and 2010, respectively. She received her Ph.D. in Informatics from The Graduate University for Advanced Studies, National Institute of Informatics, Tokyo, Japan in 2019. Currently, she is an assistant professor at the School of Information and Communication, Hanoi University of Science and Technology (HUST), Vietnam. She is currently the managing director of The International Research Center for Artificial Intelligence (BK.AI), HUST. Her research interests include network architectures, AI, and Data science.',
    },
    {
      name: 'Dr. Thanh Hung Nguyen',
      title:
        'Lecturer, Vice Dean of School of Information and Communication Technology, HUST',
      avatar:
        'https://soict.hust.edu.vn/wp-content/uploads/2017/03/DHP_5593-768x1150.jpg?fbclid=IwAR1RX_6cjWSNXRzCoH6qJvBCUxU5FEmzGcjffV25srrZ_faPOzPdsqq-uPI',
      bio: `Dr. Thanh Hung Nguyen is a lecturer, researcher, and the Vice Dean of the School of Information and Communication Technology, at Hanoi University of Science and Technology. 
      Post-doc at Verimag laboratory, working on ACROSS, a research project that aims to develop and implement an ARTEMIS cross-domain reference architecture for embedded systems. He is also working on version 2.0 of D-Finder, a tool for compositional and incremental deadlock detection and verification of systems described in the BIP (Behavior-Interaction-Priority) modeling language. 
      Ph.D. in Computer Science: He worked on theory, methods, and tools for the compositional and incremental verification of component-based systems. He was the main developer of D-Finder V1.0. He also has experience in the component-based construction of complex systems.`,
    },
    {
      name: 'Dr. Sara Thaichon',
      title: 'Senior Lecturer, Griffith University',
      avatar: 'https://minio.hisoft.com.vn/csiro/image/1.Sara%20Quach.jpg',
      bio: 'Sara Quach Thaichon is a Senior Lecturer in the Department of Marketing, Griffith University, Australia. Her research interests are in the areas of services marketing, marketing research, consumer behavior, and relationship marketing. Sara has been recognized as the Rising Star (Early Achievers Leaderboard) in the Marketing Discipline 2020 by The Australian. Sara has published over 50 A and A*-ranked journal articles since 2015. She has won several awards at top marketing conferences.',
    },
    {
      name: 'Dr. Nguyen Manh Hung',
      title: 'Associate Research Professor – Toulouse School of Economics',
      avatar:
        'https://minio.hisoft.com.vn/csiro/image/Manh-Hung Nguyen TSE.png',
      bio: 'Prof. Nguyen Manh Hung is the Associate Research Professor at Toulouse School of Economics (TSE). He got his Ph.D. in Mathematical Economics (2007: University of Paris 1 Panthéon-Sorbonne, France). Professor Nguyễn Mạnh Hùng directly manages research projects in Vietnam and France in the fields of environment, energy, and climate change.',
    },
    {
      name: 'Dr. Mai Huu Minh',
      title: 'CEO Intelligent Financial Research and Consulting',
      avatar: 'https://minio.hisoft.com.vn/csiro/image/MAIHuuMinh.240.jpg',
      bio: "Dr. Mai Huu Minh - Founder/CEO of Intelligent Financial Research & Consulting (ifrc.fr). Dr. Minh had his Ph.D. in Finance at University Paris Dauphine (France) and the master's degree in Applied Mathematics at Paris Dauphine and ENSAE ParisTech (Statistics, Econometrics, Data Science, and Machine Learning). Previously he was Head of Research and Development Department in the Paris Stock Exchange, Euronext-Life, and New York Stock Exchange. Dr. Minh is also a guest lecturer in many universities and business schools across Europe.",
    },
    {
      name: 'Dr. Lucy Cameron',
      title: 'Senior Research Consultant, CSIRO Data61',
      avatar:
        'https://minio.hisoft.com.vn/csiro/image/Lucy Cameron_print_3956.jpg',
      bio: 'Dr. Lucy Cameron was a Senior and Principal Policy Officer, and Manager in Creative Industries, Digital Economy, and Innovation in the Queensland Government for over ten years. In 2015 she was a Queensland Government Smithsonian Fellow. Lucy has a Ph.D. in Social Science from the University of Queensland (Regional Development and Broadband) and a Bachelor of Science from Sydney University. She has also undertaken research and gained funding for universities and regional development organizations, and was an Australian Civil Society representative at the World Summit for the Information Society (WSIS) in Geneva in 2005. She has authored book chapters, journal articles, and conference papers on broadband, regional development, higher education, and entrepreneurial activity. She led Vietnam’s Future Digital Economy project.',
    },
  ],
  partners: [
    {
      name: 'Prof. Nguyen Thanh Thuy',
      title: 'VNU University of Engineering and Technology',
      avatar:
        'https://minio.hisoft.com.vn/ftu-dev/Screenshot 2022-10-24 115623.png',
    },
    {
      name: 'Prof. Bui Thu Lam',
      title:
        'Associate Professor in Computer Science, Academy of Cryptography Techniques',
      avatar:
        'https://lambui.files.wordpress.com/2007/08/imglam.jpg?w=89&h=134',
    },
    {
      name: 'Assoc. Prof. Huynh Thi Thanh Binh',
      title: 'Hanoi University of Science and Technology',
      avatar:
        'https://scholar.googleusercontent.com/citations?view_op=view_photo&user=vJYe5lkAAAAJ&citpid=2',
    },
  ],
  org: [
    '/images/about-us/australian_aid.jpg',
    '/images/about-us/khcn.png',
    '/images/about-us/csiro2.jpg',
    '/images/about-us/aus4innovation-logo.svg',
    '/images/about-us/data61.png',
    '/images/about-us/griffith-logo.svg',
    '/images/about-us/ireeds.png',
    '/images/about-us/hust.png',
  ],
};

export default data;
