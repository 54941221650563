import Top from '../../components/organisation/Top';
import Sidebar from '../../components/organisation/Sidebar';
import theme from '../../commons/store/filterThemeProvider';
import FirmDetail from '../../components/firm/FirmDetail';
import FirmPageFilter from '../../components/firm/FirmPageFilter';
import { useNavigate } from 'react-router-dom';
import { Collapse } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';

const FirmDetailPage = () => {
  const [isOpenFilter, setIsOpenFilter] = useState(true);
  const [relatedServices, setRelatedServices] = useState([]);
  const [regions, setRegions] = useState([]);
  const [isApplyFilter, setApplyFilter] = useState(false);
  const [handleTriggerFilter, setTriggerFilter] = useState(false);
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const isSmaller = useMediaQuery({ maxWidth: 1040 });

  const handleSearch = () => {
    navigate('/firm', {
      replace: true,
      state: {
        relatedServices,
        regions,
      },
    });
  };

  return (
    <>
      <Top onNameChange={setName} onSearch={handleSearch} />
      <div className="organisation-content">
        <Sidebar isOpen={isOpenFilter} onSelect={setIsOpenFilter} />
        {isSmaller ? (
          <FirmPageFilter
            regions={regions}
            relatedServices={relatedServices}
            onServiceChange={setRelatedServices}
            onRegionsChange={setRegions}
            onFilterChange={setApplyFilter}
            onTriggerFilter={() => {
              setTriggerFilter(!handleTriggerFilter);
            }}
            onSearch={handleSearch}
          />
        ) : (
          <ThemeProvider theme={theme}>
            <Collapse in={isOpenFilter} orientation="horizontal">
              <FirmPageFilter
                regions={regions}
                relatedServices={relatedServices}
                onServiceChange={setRelatedServices}
                onRegionsChange={setRegions}
                onFilterChange={setApplyFilter}
                onTriggerFilter={() => {
                  setTriggerFilter(!handleTriggerFilter);
                }}
                onSearch={handleSearch}
              />
            </Collapse>
          </ThemeProvider>
        )}
        <FirmDetail />
      </div>
    </>
  );
};

export default FirmDetailPage;
