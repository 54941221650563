const RatingStar = ({ stars }) => {
  const times = new Array(stars).fill(0);
  const starLeft = new Array(5 - stars).fill(0);
  return (
    <div className="d-flex justify-content-center gap-1">
      {times.map((_, i) => (
        <span
          className="material-icons"
          style={{ color: '#EEBD40', fontSize: 15 }}
          key={`star${i}`}
        >
          star
        </span>
      ))}
      {starLeft.map((_, i) => (
        <span
          className="material-icons"
          style={{ color: '#666666', fontSize: 15 }}
          key={`starleft${i}`}
        >
          star
        </span>
      ))}
    </div>
  );
};

export default RatingStar;
