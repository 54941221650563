import * as React from 'react';
import PropTypes from 'prop-types';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import {
  AiOutlineCheck as CheckIcon,
  AiOutlineClose as CloseIcon,
} from 'react-icons/ai';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { IconContext } from 'react-icons';
import { Typography } from '@mui/material';

const Root = styled('div')(
  ({ theme }) => `
  color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
  };
  font-size: 14px;
`,
);

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,0.65)'
        : 'rgba(0,0,0,.85)'
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 100%;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <IconContext.Provider value={{ size: 20 }}>
        <CloseIcon onClick={onDelete} />
      </IconContext.Provider>
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#dff3ff'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 2px 4px 2px 10px;
  outline: 0;
  overflow: hidden;
  border-radius: 20px;

  &:hover {
    background-color: '#0dcaf0';
  }

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`,
);

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
);

export default function CustomizedHook({
  options,
  haveIcon = false,
  label,
  relatedTech,
  filterTitle,
  onChange,
  resetFilter,
  state,
  triggerReset,
}) {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: label,
    defaultValue: state ? [...state] : undefined,
    multiple: true,
    options: options,
    getOptionLabel: (option) => option,
  });

  React.useEffect(() => {
    // Handle for sectors of technologies
    if (relatedTech) {
      const newRelatedTech = { ...relatedTech };
      // Replace the keywords of "filterTitle" sector with the new selection from user
      if (value.includes('All')) {
        newRelatedTech[filterTitle] = options.slice(1);
      } else {
        newRelatedTech[filterTitle] = value;
      }
      // update the whole object (This object manages all selected keywords of each sector)
      onChange(newRelatedTech);
    } else {
      onChange(value);
    }
  }, [value]);

  React.useEffect(() => {
    if (triggerReset) value.splice(0, value.length);
  }, [resetFilter]);

  return (
    <Root>
      <div {...getRootProps()}>
        <Label {...getInputLabelProps()}>{label}</Label>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {value.map((option, index) => (
            <StyledTag label={option} {...getTagProps({ index })} />
          ))}

          <input {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li
              {...getOptionProps({ option, index })}
              className="d-flex justify-content-between align-items-center"
            >
              <div className="d-flex align-items-center flex-shrink-1">
                {haveIcon ? (
                  <div style={{ width: '10%', marginRight: '10px' }}>
                    <img
                      src={`https://countryflagsapi.com/svg/${option.toLowerCase()}`}
                      onError={(e) => {
                        e.currentTarget.src = '/images/avatar/default-flag.jpg';
                      }}
                      width="100%"
                    />
                  </div>
                ) : null}

                <Typography fontSize={14} noWrap={true}>
                  {option}
                </Typography>
              </div>
              <div>
                <IconContext.Provider value={{ size: 20 }}>
                  <CheckIcon />
                </IconContext.Provider>
              </div>
            </li>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
}
