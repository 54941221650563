import { useState, useEffect } from 'react';
import { Typography, AccordionDetails, Button } from '@mui/material';
import { MdFilterList } from 'react-icons/md';
import { IconContext } from 'react-icons';
import { GiGraduateCap } from 'react-icons/gi';
import '../../pages/organisation/OrganisationPage.scss';
import { ThemeProvider } from '@mui/material/styles';
import {
  FilterHeader,
  theme,
  accordionTheme,
  Accordion,
  AccordionSummary,
  FilterAccordionSummary,
} from '../../commons/components-lib';
import { useMediaQuery } from 'react-responsive';
import CustomizedHook from '../AutocompleteSearch';

const ExpertFilter = ({
  onExpertChange,
  relatedExperts,
  resetFilter,
  state,
  triggerReset,
}) => {
  const expertFilter = [
    { label: '< 20', value: '1-19' },
    { label: '20 - 50', value: '20-50' },
    { label: '> 50', value: '50-max' },
  ];

  const handleChange = (selectedLabels) => {
    const selectedObj = expertFilter.filter((o) =>
      selectedLabels.includes(o.label),
    );
    onExpertChange(selectedObj.map((o) => o.value));
  };

  useEffect(() => {
    // Not sure why need to directly update on the state value, use onExpertChange doesn't trigger the changes
    relatedExperts.splice(0, relatedExperts.length);
    onExpertChange([]);
  }, [resetFilter]);

  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <IconContext.Provider
            value={{ color: '#2770F1', className: 'info-icon', size: 25 }}
          >
            <GiGraduateCap />
          </IconContext.Provider>
          <Typography fontSize={16} noWrap={true}>
            Related Experts
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CustomizedHook
          options={expertFilter.map((e) => e.label)}
          label="Choose related experts"
          onChange={handleChange}
          resetFilter={resetFilter}
          triggerReset={triggerReset}
          state={state}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const TechnologyPageFilter = ({
  onExpertChange,
  onFilterChange,
  relatedExperts,
  onTriggerFilter,
  onSearch,
  state,
}) => {
  const [resetFilter, setResetFilter] = useState(false);
  const [triggerReset, setTriggerReset] = useState(false);
  const isSmaller = useMediaQuery({ maxWidth: 1040 });

  const handleApplyFilter = () => {
    onFilterChange(true);
    onTriggerFilter();
    onSearch && onSearch();
    setTriggerReset(false);
  };

  const handleResetFilter = () => {
    setResetFilter(!resetFilter);
    onFilterChange(true);
    onTriggerFilter();
    setTriggerReset(true);
  };

  return (
    <div className="filter">
      {isSmaller ? (
        <ThemeProvider theme={accordionTheme}>
          <Accordion>
            <FilterAccordionSummary>
              <FilterHeader />
            </FilterAccordionSummary>
            <AccordionDetails>
              <div className="filter-main">
                <ThemeProvider theme={accordionTheme}>
                  <ExpertFilter
                    onExpertChange={onExpertChange}
                    relatedExperts={relatedExperts}
                    resetFilter={resetFilter}
                    state={state?.relatedExperts}
                    triggerReset={triggerReset}
                  />
                </ThemeProvider>
              </div>

              <div className="filter-btn-container">
                <ThemeProvider theme={theme}>
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<MdFilterList />}
                    fullWidth
                    style={{ marginTop: 10, fontSize: 12, fontWeight: 600 }}
                    onClick={handleApplyFilter}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<MdFilterList />}
                    fullWidth
                    style={{
                      marginTop: 10,
                      fontSize: 12,
                      fontWeight: 600,
                      background: '#ffffff',
                      color: '#3D56B2',
                      borderColor: '#3D56B2',
                    }}
                    onClick={handleResetFilter}
                  >
                    Reset Filter
                  </Button>
                </ThemeProvider>
              </div>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
      ) : (
        <>
          <FilterHeader />
          <div className="filter-main">
            <ThemeProvider theme={accordionTheme}>
              <ExpertFilter
                onExpertChange={onExpertChange}
                relatedExperts={relatedExperts}
                resetFilter={resetFilter}
                state={state?.relatedExperts}
                triggerReset={triggerReset}
              />
            </ThemeProvider>
          </div>

          <div className="filter-btn-container">
            <ThemeProvider theme={theme}>
              <Button
                color="primary"
                variant="contained"
                endIcon={<MdFilterList />}
                fullWidth
                style={{ marginTop: 10, fontSize: 12, fontWeight: 600 }}
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
              <Button
                variant="contained"
                endIcon={<MdFilterList />}
                fullWidth
                style={{
                  marginTop: 10,
                  fontSize: 12,
                  fontWeight: 600,
                  background: '#ffffff',
                  color: '#3D56B2',
                  borderColor: '#3D56B2',
                }}
                onClick={handleResetFilter}
              >
                Reset Filter
              </Button>
            </ThemeProvider>
          </div>
        </>
      )}
    </div>
  );
};

export default TechnologyPageFilter;
