import '../../../pages/expert/ExpertPage.scss';
import '../../expert/ExpertDetail/ExpertDetail.css';
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Pagination from '@mui/material/Pagination';

export function OrganisationList(props) {
  return props.orgs?.map((item) => (
    <Grid item xs={12} sm={4} md={3} key={item.id}>
      <div className="similar-expert-item">
        <div className="ava text-center">
          <a href={'/organisation/' + item.id}>
            <img
              onError={(e) => {
                e.currentTarget.src = '/images/avatar/company.jpg';
              }}
              className=""
              src={item.img}
              alt=""
            />
          </a>
        </div>
        <p className="name mb-4 text-center">
          <a href={'/organisation/' + item.id}>{item.name}</a>
        </p>
        <div className="expert-detail-info">
          <ul className="p-0">
            <li className="row">
              <div className="col-1">
                <img src="/images/icons/expert.svg" />
              </div>
              <span className="col-7">Related Experts</span>
              <span className="col">{item?.related_authors_vietnamese}</span>
            </li>
            {/* <li className="row">
              <div className="col-1">
                <img src="/images/icons/h-index.svg" />
              </div>
              <span className="col-7">Size</span>
              <span className="col">{item?.Company_size}</span>
            </li> */}
          </ul>
        </div>
      </div>
    </Grid>
  ));
}

const RelatedOrganisation = ({ orgs }) => {
  const [sortBy, setSortBy] = useState('related_authors');
  const [page, setPage] = useState(1);
  const [organisation, setOrganisation] = useState([]);

  useEffect(() => {
    let orgsSort = orgs.sort(compare);
    const organisation = orgsSort.slice((page - 1) * 12, page * 12);
    setOrganisation(organisation);
  }, [page, orgs, sortBy]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const compare = (a, b) => {
    if (sortBy === 'related_authors')
      return a.related_authors > b.related_authors ? -1 : 1;
    else return a.Company_size > b.Company_size ? -1 : 1;
  };

  return (
    <div className="similar-expert d-flex flex-column mt-4">
      <div className="d-flex flex-row flex-grow-1 justify-content-between align-items-start mb-3">
        <span className="similar-expert-title">RELATED ORGANISATIONS</span>
        {/* <div className="similar-expert-sort d-flex flex-row align-items-center">
          <h5>Sort by</h5>
          <select
            className="border-0 form-select"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="related_authors" style={{ fontSize: '13px' }}>
              Related authors
            </option>
            <option value="Company_size" style={{ fontSize: '13px' }}>
              Company size
            </option>
          </select>
          <button
            className="btn btn-light d-flex align-items-center border-0 p-0"
            title="Sort"
            disabled
          >
            <span className="material-icons">unfold_more</span>
          </button>
        </div> */}
      </div>
      <div className="related-experts">
        <Grid container rowSpacing={2} columnSpacing={8}>
          <OrganisationList orgs={organisation} sortBy={sortBy} />
        </Grid>
      </div>
      <div className="mt-5 text-center">
        <Pagination
          count={Math.ceil(orgs.length / 12)}
          page={page}
          onChange={handleChange}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default RelatedOrganisation;
