import { useState, useEffect } from 'react';
import Top from '../../components/organisation/Top';
import Sidebar from '../../components/organisation/Sidebar';
import FirmContent from '../../components/firm/FirmContent';
import { Collapse, ThemeProvider } from '@mui/material';
import SearchContext from '../../commons/store/search-context';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import theme from '../../commons/store/filterThemeProvider';
import FirmPageFilter from '../../components/firm/FirmPageFilter';

const FirmPage = () => {
  const [isOpenFilter, setIsOpenFilter] = useState(true);
  const [relatedServices, setRelatedServices] = useState([]);
  const [regions, setRegions] = useState([]);
  const [isApplyFilter, setApplyFilter] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [name, setName] = useState('');
  const [state, setState] = useState(useLocation().state);
  const searchContext = useContext(SearchContext);
  const isSmaller = useMediaQuery({ maxWidth: 1040 });

  const handleTriggerFilter = () => {
    let oldName = searchContext.name;
    searchContext.handleOnSearch({
      type: 'firm',
      name: name,
    });
    setState(null);
    if (oldName === name) {
      setTriggerFilter(!triggerFilter);
    }
  };

  //   useEffect(() => {
  //     if (state) {
  //       setRelatedExperts(state.relatedExperts);
  //     }
  //   }, []);

  return (
    <>
      <Top onNameChange={setName} />
      <div className="organisation-content">
        <Sidebar isOpen={isOpenFilter} onSelect={setIsOpenFilter} />
        {isSmaller ? (
          <FirmPageFilter
            onServiceChange={setRelatedServices}
            regions={regions}
            relatedServices={relatedServices}
            onRegionsChange={setRegions}
            onFilterChange={setApplyFilter}
            onTriggerFilter={handleTriggerFilter}
            state={state}
          />
        ) : (
          <ThemeProvider theme={theme}>
            <Collapse in={isOpenFilter} orientation="horizontal">
              <FirmPageFilter
                onServiceChange={setRelatedServices}
                regions={regions}
                relatedServices={relatedServices}
                onRegionsChange={setRegions}
                onFilterChange={setApplyFilter}
                onTriggerFilter={handleTriggerFilter}
                state={state}
              />
            </Collapse>
          </ThemeProvider>
        )}
        <FirmContent
          name={name}
          relatedServices={relatedServices}
          regions={regions}
          isApplyFilter={isApplyFilter}
          handleTriggerFilter={triggerFilter}
          state={state}
        />
      </div>
    </>
  );
};

export default FirmPage;
