const { useState, useEffect } = require('react');
import {
  getAllLocations,
  getLocationInformation,
} from '../../commons/apis/services/map';
import LocationGeneralInfo from './LocationGeneralInfo';
import MapBox from './MapBox';
import './index.css';
// import StyleMapMode from './StyleMapMode';

const Map = (props) => {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('Hanoi');
  const [locationInfo, setLocationInfo] = useState({});

  const updateSelectedLocation = (circleId) => {
    setSelectedLocation(circleId);
  };
  useEffect(() => {
    const fetchData = async () => {
      const locations = await getAllLocations();
      setSelectedLocations(locations);
      const locationInfo = await getLocationInformation(selectedLocation);
      setLocationInfo(locationInfo);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const locationInfo = await getLocationInformation(selectedLocation);
      setLocationInfo(locationInfo);
    };
    fetchData();
  }, [selectedLocation]);

  return (
    <div className="map-box">
      <MapBox updateSelectedLocation={updateSelectedLocation} />
      {/* <LocationGeneralInfo data={locationInfo}></LocationGeneralInfo> */}
    </div>
  );
};

export default Map;
