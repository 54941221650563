export const API = {
  // experts
  GET_TOP_EXPERTS: '/expert/top_experts?',
  GET_EXPERTS_BY_ID: '/expert/expert_by_id?',
  GET_ALL_LOCATIONS: '/expert/all_locations',
  GET_ALL_LANGUAGE: '/expert/all_languages',
  GET_ALL_OCCUPATIONS: '/expert/all_occupations',
  GET_EXPERTS_STATISTIC: '/expert/get_expert_statistic?',
  GET_SIMILAR_EXPERT: '/expert/get_similar?',
  GET_LIST_PAPERS: '/expert/list-paper',
  GET_SEARCH_EXPERTS: '/search/search_expert?',

  //papers
  GET_PAPER_RELATED_EXPERTS: '/paper/get-related-experts?',

  //firms
  GET_LIST_FIRMS: 'clutch/orgs?',
  GET_FIRM_BY_ID: '/clutch/orgs/',

  // orgs
  GET_ORGANIZATIONS_BY_ID: '/organizations/organization_by_id?',
  GET_TOP_ORGANIZATIONS: '/organizations/top_organizations_v2?',
  GET_ORG_RELATED_EXPERTS: '/organizations/related_expert?',
  GET_PROMINENT_EXPERTS: 'organizations/top-authors-papers',
  GET_SEARCH_ORGANIZATIONS: '/search/search_organization?',

  // technologies
  GET_TOP_TECHNOLOGIES: '/technologies/top_technologies?',
  GET_TECHNOLOGIES_NETWORK: '/technologies/get_technology_network?',
  GET_RELATED_EXPERTS: '/technologies/get-related-experts?',
  GET_TECHNOLOGIES_BY_ID: '/technologies/',
  GET_ALL_TECHNOLOGIES: '/technologies',
  GET_SEARCH_TECHNOLOGIES: '/search/search_technology?',
  GET_ALL_SECTORS: '/technologies/get-all-name-of-related-industries/',
  GET_KEYWORDS_FROM_SECTOR:
    'https://api-dev.vnconnect.info/technologies/get-detail-of-related-industry/?',

  // map
  MAP_GET_ALL_LOCATIONS: '/map/get_all_locations',
  MAP_GET_ALL_ORGANIZATIONS: '/map/get_all_organisations',
  MAP_GET_LOCATION_INFORMATION: 'map/get_location_information',
  MAP_GET_ORG_INFORMATION_BY_LOCATION: '/map/get_org_information_by_location',
  MAP_GET_EXPERTS_BY_LOCATION: '/map/get_experts_by_location',
  MAP_GET_EXPERT_STATISTICS_BY_LOCATIONS:
    '/map/get_expert_statistics_by_locations',
};
