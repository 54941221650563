import React, { useState, useEffect, useMemo } from 'react';
import '../../pages/organisation/OrganisationPage.scss';
import { getSearchOrganizations } from '../../commons/apis/services/organizations.js';
import Spinner from '../Spinner';
import Pagination from '@mui/material/Pagination';
import SearchContext from '../../commons/store/search-context';
import { useContext } from 'react';
import { getSearchExpert } from '../../commons/apis/services/expert.js';

export function ListExpertise(props) {
  const listExpertise = useMemo(() => {
    return props.expertise_name.splice(0, 10);
  }, [props.expertise_name]);

  return (
    <>
      {listExpertise.map((item, index) => (
        <div
          key={item}
          className="item-expertise d-flex justify-content-center align-items-center me-2 mb-3 btn btn-info border-0"
        >
          <label className="text-capitalize">{item}</label>
        </div>
      ))}
      {props.expertise_name && props.expertise_name.length > 10 && (
        <div className="item-expertise d-flex justify-content-center align-items-center me-2 mb-3 btn btn-info border-0">
          <label className="text-capitalize">...</label>
        </div>
      )}
    </>
  );
}

const OrganisationContent = (props) => {
  const [relatedExperts, setRelatedExperts] = useState('');
  const [allExpert, setAllExpert] = useState('');
  const [relatedExperties, setRelatedExpertises] = useState('');
  const [sortBy, setSortBy] = useState('related_authors_vietnamese');
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const [dataOrganizations, setDataOrganizations] = useState([]);
  const [page, setPage] = useState(1);
  const [articleNumber, setArticleNumber] = useState(0);
  const [isSortByDesc, setSortByDesc] = useState(true);
  const searchContext = useContext(SearchContext);

  const fetchData = async () => {
    try {
      setLoadingOrganizations(true);
      const queryObj = {
        name: searchContext.name,
        size: 10,
        page: page,
        isApplyFilter: props.state ? true : props.isApplyFilter,
        sortBy: sortBy,
        sortByDesc: isSortByDesc,
        expertSizes: props.state
          ? props.state.relatedExperts
          : props.relatedExperts,
        relatedTechs: props.state ? props.state.relatedTech : props.relatedTech,
        countries: props.state ? props.state.countries : props.countries,
      };
      const { data: dataObject } = await getSearchOrganizations(queryObj);
      if (searchContext.name || props.state ? true : props.isApplyFilter)
        setRelatedExperts(dataObject.data.count_related_data.experts);
      else
        setRelatedExperts('');
      setRelatedExpertises(dataObject.data.count_related_data.technologies);
      setDataOrganizations([...dataObject.data.org_data]);
      setArticleNumber(dataObject.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOrganizations(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: dataObject } = await getSearchExpert({
          name: '',
          sortBy: 'related_authors_vietnamese',
          sortByDesc: true,
          size: 5,
          page: 1,
          relatedOrgs: [],
          hIndexs: [],
          relatedTechs: [],
        });
        setAllExpert(dataObject.count);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    fetchData();
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleOnChangeSortType = () => {
    setSortByDesc(!isSortByDesc);
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    sortBy,
    isSortByDesc,
    props.handleTriggerFilter,
    searchContext.name,
  ]);

  const renderListItemOrganizations = () => {
    return dataOrganizations?.map((item) => (
      <div
        key={item.id}
        id="item-organizations"
        className="form-control d-flex flex-row align-items-start w-100 flex-grow-1 mb-4"
      >
        <div className="infor d-flex flex-row align-items-between justify-content-between w-100">
          <div className="d-flex flex-row w-100 infor-header">
              <a href={'/organisation/' + item.id} className="img shadow rounded">
                <img src={item.img ? item.img : '/images/avatar/company.jpg'} 
                  onError={(e) => {
                    e.currentTarget.src = '/images/avatar/company.jpg';
                  }}
                />
              </a>
            <div className="d-flex flex-column align-items-start justify-content-start ms-4">
              <h3>
                <a href={'/organisation/' + item.id} className="name">
                  {item.name}
                </a>
              </h3>
              <p className="org_des">{item.Org_des}</p>
              <div className="d-flex flex-grow-1 flex-wrap mb-mobile">
                <ListExpertise expertise_name={[...new Set(item.expertise_name.map(name => name.toLowerCase()))]} />
              </div>
            </div>
          </div>
          <div className="note d-flex flex-column border-start">
            <div className="detail-info-2-cols">
              <div className="d-flex">
                <span className="material-icons me-2 icon-note">
                  location_on
                </span>
                <span className="me-2 text-title">Location</span>
              </div>
              {item.country ? (
                <label className="text-note">{item.country}</label>
              ) : (
                <label className="text-note">No data</label>
              )}
              <div className="d-flex align-items-center">
                <span className="material-icons me-2 icon-note">school</span>
                <span className="me-2 text-title">Related Experts</span>
              </div>
              {item.related_authors_vietnamese ? (
                <label className="text-note">
                  {item.related_authors_vietnamese}
                </label>
              ) : (
                <label className="text-note">No data</label>
              )}
              {/* <div className="d-flex">
                <span className="material-icons me-2 icon-note">people</span>
                <span className="me-2 text-title">Size</span>
              </div>
              {item.company_size ? (
                <label className="text-note">{item.company_size[0]}</label>
              ) : (
                <label className="text-note">No data</label>
              )} */}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="content h-100" id="org-list">
      <div className="d-flex flex-column w-100">
        <h3 className='mobile-h3'>Organisation Search Results</h3>
      </div>
      <div className="bar d-flex w-100 flex-row border justify-content-between align-items-center form-control p-0 mt-3">
        <button className="btn btn-light px-0 border-start-0 border-top-0 border-bottom-0">
          <span className="material-icons">chevron_left</span>
        </button>
        <div className="related d-flex flex-row flex-grow-1 py-3 justify-content-start">
          <div className="d-flex flex-column justify-content-center align-items-center border-end">
            <h6>Organisation Results</h6>
            <label>{articleNumber}</label>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center border-end">
            <h6>Related Experts</h6>
            <label>{relatedExperts ? relatedExperts : allExpert}</label>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h6 style={{ color: '#42BBFF' }}>Related Technologies</h6>
            <label>{relatedExperties}</label>
          </div>
        </div>
        <button className="btn btn-light px-0 border-end-0 border-top-0 border-bottom-0">
          <span className="material-icons">chevron_right</span>
        </button>
      </div>
      <div className="sort d-flex flex-row align-items-center my-4">
        <h5>Sort by</h5>
        <select
          className="border-0 form-select"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
        >
          <option value="related_authors_vietnamese">Related experts</option>
          <option value="name">Name</option>
        </select>
        <button
          className="btn btn-light d-flex align-items-center border-0 p-0"
          title="Sort"
          onClick={handleOnChangeSortType}
        >
          <span className="material-icons">unfold_more</span>
        </button>
      </div>
      <div className="w-100">
        {loadingOrganizations ? <Spinner /> : renderListItemOrganizations()}
      </div>
      <div className="d-flex justify-content-center">
        <Pagination
          count={Math.ceil(articleNumber / 10)}
          page={page}
          onChange={handleChange}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default OrganisationContent;
