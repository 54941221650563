import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { dataTechDetail } from '../../mock-data';
import RelatedExperts from './RelatedExperts';
import RelatedOrganisation from './RelatedOrganisation';
import Spinner from '../../Spinner';
import './TechnologyDetail.css';
import { getTechnologiesById } from '../../../commons/apis/services/technologies';
import { logEvent } from 'firebase/analytics';
import analytics from '../../../commons/firebase/index.js';

const TechnologyDetail = () => {
  const { id } = useParams();
  const [technology, setTechnology] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    logEvent(analytics, 'view_detail_technology', {
      tech_id: id,
      page_path: window.location.pathname,
      content_id: id
    })
    const fetchData = async () => {
      const res = await getTechnologiesById(id);
      setTechnology(res.data);
      setLoading(false);
    };
    fetchData();
  },[id]);

  return isLoading ? (
    <div className="d-flex flex-grow-1 justify-content-center content">
      <Spinner />
    </div>
  ) : (
    <div id="content-tech" className="content">
      <div className="header-title d-flex flex-row align-items-center">
        <div className="info-tech d-flex flex-column align-items-start justify-content-center flex-grow-1 ps-5">
          <h2>{technology.name}</h2>
        </div>
      </div>
      <RelatedExperts similarExpert={technology.expert_datas} />
      <RelatedOrganisation orgs={technology.org_data} />
    </div>
  );
};
export default TechnologyDetail;
