import { httpService } from '../services';
import { API } from '../endPoint';

const getAllLocations = async (mode) => {
  var url = API.MAP_GET_ALL_LOCATIONS;
  if (mode) url += `?mode=${mode}`;
  const res = await httpService.get(url);
  if (res.code === 200) {
    return res.data;
  } else return [];
};

const getAllOrganizations = async () => {
  var url = API.MAP_GET_ALL_ORGANIZATIONS;
  const res = await httpService.get(url);
  return res;
};

const getLocationInformation = async (location, mode = 'region') => {
  var url = API.MAP_GET_LOCATION_INFORMATION;
  url += `?location=${location}&mode=${mode}`;
  const res = await httpService.get(url);
  if (res.code === 200) {
    return res.data;
  } else return null;
};

const getOrgInformationByLocation = async (location, mode = 'region') => {
  var url = API.MAP_GET_ORG_INFORMATION_BY_LOCATION;
  url += `?location=${location}&mode=${mode}`;
  const res = await httpService.get(url);
  return res;
};

const getExpertsByLocation = async (mode = 'region') => {
  var url = API.MAP_GET_EXPERTS_BY_LOCATION;
  url += `?mode=${mode}`;
  const res = await httpService.get(url);
  if (res.code !== 200) {
    return []
  }
  return res.data
};

const getExpertStatisticByLocations = async (
  mode = 'region',
  list_locations = 'Sydney,Ha Noi',
) => {
  var url = API.MAP_GET_EXPERT_STATISTICS_BY_LOCATIONS;
  url += `&mode=${mode}&list_locations=${list_locations}`;
  const res = await httpService.get(url);
  return res;
};

export {
  getAllLocations,
  getAllOrganizations,
  getLocationInformation,
  getOrgInformationByLocation,
  getExpertsByLocation,
  getExpertStatisticByLocations,
};
