import '../../../pages/expert/ExpertPage.scss';
import './ExpertDetail.css';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../Spinner';
import { getExpertById } from '../../../commons/apis/services/expert.js';
import TabContentExpert from './TabContentExpert';
import SimilarExperts from './SimilarExperts';
import { logEvent } from 'firebase/analytics';
import analytics from '../../../commons/firebase/index.js';

const ExpertDetail = () => {
  const { id } = useParams();
  const [expert, setExpert] = useState({});
  const [loading, setLoading] = useState(false);


  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await getExpertById(id);
      setExpert(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    logEvent(analytics, 'view_detail_expert', {
      expert_id: id,
      page_path: window.location.pathname,
      content_id: id
    })
  }, [id]);

  return loading ? (
    <div className="d-flex flex-grow-1 justify-content-center">
      <Spinner />
    </div>
  ) : (
    <div id="content-expert" className="h-100 d-flex flex-column">
      <div className="header-title d-flex flex-row align-items-center">
        <div className="avatar">
          <img
            onError={(e) => {
              e.currentTarget.src = '/images/avatar/default.jpg';
            }}
            className="avatar-img h-100"
            src={expert.avatar_url}
            alt=""
          />
        </div>
        <div className="infor-expert d-flex flex-column align-items-start justify-content-center flex-grow-1 ps-5">
          <h2>{expert.name}</h2>
          <div>
            {expert.about &&
              expert.about.split('.').map((e) => (
                <p key={e.trim()} className="label-text">
                  {e.trim()}
                </p>
              ))}
            {/* <span className='span-text'>
              Working Place
            </span> */}
            {/* <label className='label-text ms-3'>{expert.about}</label> */}
          </div>
        </div>
      </div>
      <div className="bar-expert border d-flex flex-row justify-content-between mt-4">
        <div className="summary-expert d-flex flex-column justify-content-start ">
          <h4>Summary Stats</h4>
          <div>
            <ul className="summary-expert-items">
              <li className="d-flex flex-row align-items-center my-4">
                <img className="me-3" src="/images/icons/patents.svg" />
                <span className="title-text me-5">I10-index</span>
                <span className="value-text">{expert?.i10_index}</span>
              </li>
              <li className="d-flex flex-row align-items-center my-4">
                <img className="me-3" src="/images/icons/citations.svg" />
                <span className="title-text me-5">Citations</span>
                <span className="value-text">{expert?.citations}</span>
              </li>
              <li className="d-flex flex-row align-items-center my-4">
                <img className="me-3" src="/images/icons/h-index.svg" />
                <span className="title-text me-5">H-index</span>
                <span className="value-text">{expert?.h_index}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="study-expert d-flex flex-column justify-content-start ms-5 ps-4 border-start">
          <h4>Fields Of Study</h4>
          <div className="mt-4 text-capitalize title-text">
            {expert.expertise && expert.expertise.join(', ')}
          </div>
        </div>
        <div className="bio-expert d-flex flex-column justify-content-start ms-5 ps-4 border-start">
          <h4>Bio</h4>
          <div className="mt-4">
            <span className="span-text">Website:</span>
            {expert.profile_urls?.length > 0 &&
              expert.profile_urls[0].indexOf('http') < 0 && (
                <div>
                  <a
                    className="span-text"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://scholar.google.com.au/${expert.profile_urls[0]}`}
                  >
                    https://scholar.google.com.au{expert.profile_urls[0]}{' '}
                  </a>{' '}
                </div>
              )}
            {expert.profile_urls?.length > 0 &&
              expert.profile_urls[0].indexOf('http') >= 0 && (
                <div>
                  <a
                    className="span-text"
                    target="_blank"
                    rel="noreferrer"
                    href={expert.profile_urls[0]}
                  >
                    {expert.profile_urls[0]}{' '}
                  </a>{' '}
                </div>
              )}
          </div>
        </div>
      </div>
      <TabContentExpert expert={expert} papers={expert?.data_count_papers?.filter(item => item.year > 1975)} id={id} />
      <SimilarExperts />
    </div>
  );
};

export default ExpertDetail;
