import Avatar from '@mui/material/Avatar';
import { getProminentExperts } from '../../../commons/apis/services/organizations';
import Spinner from '../../Spinner';
import { useState, useEffect } from 'react';
import { sample } from 'lodash';
import Pagination from '@mui/material/Pagination';

const ProminentProfiles = (props) => {
  const [papers, setPapers] = useState([]);
  const [papersRender, setPaperRender] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [maxPaper, setMax] = useState(0);
  const [page, setPage] = useState(1);
  const LIMIT = 5;
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (props.org_id) {
      const fetchData = async () => {
        const res = await getProminentExperts(
          props.org_id,
          props.related_authors,
        );
        const papers = res.data.map((item) => {
          return {
            ...item,
            color: sample([
              '#A1CEE2',
              '#AFDF90',
              '#FF9A9B',
              '#FFBF76',
              '#CDB2D5',
            ]),
          };
        });
        setPapers(papers);
        setMax(...papers.map((e) => e.papers_count));
        setIsLoading(false);
      };
      fetchData();
    }
  }, [props.org_id]);

  useEffect(() => {
    const papersRender = papers.slice((page - 1) * LIMIT, page * LIMIT);
    setPaperRender(papersRender);
  }, [page, papers]);

  return (
    <div id="prominent-profiles">
      <h5 className="title">Prominent Author Profiles</h5>

      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          {papersRender &&
            papersRender.map((item) => (
              <div className="row" key={item.user_id}>
                <div className="col-1 exp-avatar">
                  <a href={`/expert/${item._id}`}>
                    <Avatar alt={item.name} src={item.avatar_url}></Avatar>
                  </a>
                </div>
                <div className="col">
                  <a className="exp-name" href={`/expert/${item._id}`}>
                    <p className="exp-name">{item.name}</p>
                  </a>
                  <div>
                    <div
                      className="bar"
                      style={{
                        backgroundColor: item.color,
                        width: (item.papers_count / maxPaper) * 100 + '%',
                      }}
                    >
                      {item.papers_count}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div className='papers-pagination'>
            <Pagination
              count={Math.ceil(papers.length / LIMIT)}
              page={page}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
              size="small"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default ProminentProfiles;
