import '../../pages/organisation/OrganisationPage.scss';
import './TechnologyContent.scss';
import Spinner from '../Spinner';
import { useState, useEffect, useContext } from 'react';
import { getSearchTechnologies } from '../../commons/apis/services/technologies';
import SearchContext from '../../commons/store/search-context';
import { ListExpertise } from '../organisation/OrganisationContent';
import { Tooltip, Pagination } from '@mui/material';

const TechnologyContent = (props) => {
  const [sortBy, setSortBy] = useState('name');
  const [isSortByDesc, setSortByDesc] = useState(true);
  const [loadingTechs, setLoadingTechs] = useState(false);
  const [page, setPage] = useState(1);
  const [techData, setTechData] = useState([]);
  const [resultNum, setResultNum] = useState(0);
  const searchContext = useContext(SearchContext);

  const fetchData = async () => {
    try {
      setLoadingTechs(true);
      const { data: dataObject } = await getSearchTechnologies({
        name: searchContext.name,
        size: 10,
        page: page,
        sortBy: sortBy,
        sortByDesc: isSortByDesc,
        expertSizes: props.state
          ? props.state.relatedExperts
          : props.relatedExperts,
      });
      setTechData(dataObject.data);
      setResultNum(dataObject.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTechs(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    sortBy,
    isSortByDesc,
    props.handleTriggerFilter,
    searchContext.name,
  ]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleOnChangeSortType = () => {
    setSortByDesc(!isSortByDesc);
  };

  const renderListItemTechnologies = () =>
    techData?.map((item) => (
      <div
        key={item._id}
        id="item-organizations"
        className="form-control d-flex flex-row align-items-start w-100 flex-grow-1 mb-4"
      >
        <div className="infor d-flex flex-row align-items-between justify-content-between w-100">
          <div className="d-flex flex-column align-items-start justify-content-start ms-4 mb-mobile">
            <h3>
              <a
                href={'technology/' + item._id}
                className="name text-uppercase"
              >
                {item.name}
              </a>
            </h3>
            <div
              style={{
                color: '#666',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              <p>Related</p>
            </div>
            <div className="d-flex flex-grow-1 flex-wrap">
              <ListExpertise
                expertise_name={[...item.sub_concept, ...item.super_concept]}
              />
            </div>
            <div
              className="d-flex w-100 gap-5 related-item"
              style={{
                color: '#666',
                fontSize: '14px',
                marginTop: '24px',
                fontWeight: 500,
              }}
            >
              <div className="w-50">
                <p>ORGANISATIONS</p>
                <div className="d-flex gap-1 flex-wrap">
                  {item.data_company.map((company) => (
                    <Tooltip
                      key={company._id}
                      title={<span className="h6">{company.name}</span>}
                      placement="top"
                      arrow
                    >
                      <a href={`/organisation/${company._id}`}>
                        <img
                          className="similar-expert-avatar"
                          src={
                            company.img
                              ? company.img
                              : '/images/avatar/company.jpg'
                          }
                          alt="similar expert avatar"
                        />
                      </a>
                    </Tooltip>
                  ))}
                </div>
              </div>
              <div className="w-50">
                <p>EXPERTS</p>
                <div className="d-flex gap-2 flex-wrap">
                  {item.experts.map((expert) => (
                    <Tooltip
                      key={expert._id}
                      title={<span className="h6">{expert.name}</span>}
                      placement="top"
                      arrow
                    >
                      <a href={`/expert/${expert._id}`}>
                        <img
                          className="similar-expert-avatar"
                          src={expert.avatar_url}
                          alt="similar expert avatar"
                        />
                      </a>
                    </Tooltip>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="note d-flex flex-column border-start">
            <div className="detail-info-2-cols">
              <div className="d-flex">
                <span className="material-icons me-2 icon-note">apartment</span>
                <span className="me-2 text-title">Organisations:</span>
              </div>
              {item.count_number_information ? (
                <label className="text-note">
                  {item.count_number_information.organization}
                </label>
              ) : (
                <label className="text-note">No data</label>
              )}
              <div className="d-flex">
                <span className="material-icons me-2 icon-note">school</span>
                <span className="me-2 text-title">Experts:</span>
              </div>
              {item.related_authors ? (
                <label className="text-note">{item.related_authors}</label>
              ) : (
                <label className="text-note">No data</label>
              )}
              <div className="d-flex">
                <span className="material-icons me-2 icon-note">
                  credit_score
                </span>
                <span className="me-2 text-title">Related:</span>
              </div>
              {item.count_number_information ? (
                <label className="text-note">
                  {item.count_number_information.sub +
                    item.count_number_information.super}
                </label>
              ) : (
                <label className="text-note">No data</label>
              )}
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="content h-100">
      <div className="d-flex flex-column w-100">
        <h3>Technology Search Results</h3>
        <div className="bar d-flex w-100 flex-row border justify-content-between align-items-center form-control p-0 mt-3">
          <button className="btn btn-light px-0 border-start-0 border-top-0 border-bottom-0">
            <span className="material-icons">chevron_left</span>
          </button>
          <div className="related d-flex flex-row flex-grow-1 py-3 justify-content-start ps-4">
            <div className="d-flex flex-column justify-content-center align-items-center border-end">
              <h6>Technology Results</h6>
              <label>{resultNum.count_tech}</label>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h6 style={{ color: '#42BBFF' }}>Related Experts</h6>
              <label>{resultNum.count_expert}</label>
            </div>
          </div>
          <button className="btn btn-light px-0 border-end-0 border-top-0 border-bottom-0">
            <span className="material-icons">chevron_right</span>
          </button>
        </div>
        <div className="sort d-flex flex-row align-items-center my-4">
          <h5>Sort by</h5>
          <select
            className="border-0 form-select"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="name">Name</option>
            <option value="related_experts">Related experts</option>
          </select>
          <button
            className="btn btn-light d-flex align-items-center border-0 p-0"
            title="Sort"
            onClick={handleOnChangeSortType}
          >
            <span className="material-icons">unfold_more</span>
          </button>
        </div>
        <div className="w-100">
          {loadingTechs ? <Spinner /> : renderListItemTechnologies()}
        </div>
        <div className="d-flex justify-content-center">
          <Pagination
            count={Math.ceil(resultNum.count_tech / 10)}
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
};

export default TechnologyContent;
