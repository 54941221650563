import React from 'react';
import Top from '../../components/organisation/Top';
import Sidebar from '../../components/organisation/Sidebar';
import ExpertPageFilter from '../../components/expert/ExpertPageFilter';
import { Collapse, ThemeProvider } from '@mui/material';
import ExpertDetail from '../../components/expert/ExpertDetail';
import { useNavigate } from 'react-router-dom';
import theme from '../../commons/store/filterThemeProvider';
import { useMediaQuery } from 'react-responsive';

const ExpertDetailPage = () => {
  const [isOpenFilter, setIsOpenFilter] = React.useState(true);
  const [relatedTech, setRelatedTech] = React.useState([]);
  const [org, setOrg] = React.useState([]);
  const [hIndexes, setHIndexes] = React.useState([]);
  const [isApplyFilter, setApplyFilter] = React.useState(false);
  const [handleTriggerFilter, setTriggerFilter] = React.useState(false);
  const [name, setName] = React.useState('');
  const isSmaller = useMediaQuery({ maxWidth: 1040 });
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate('/expert', {
      replace: true,
      state: {
        relatedTech,
        org,
        hIndexes,
      },
    });
  };
  return (
    <>
      <Top onSearch={handleSearch} onNameChange={setName} />
      <div className="organisation-content">
        <Sidebar isOpen={isOpenFilter} onSelect={setIsOpenFilter} />
        {isSmaller ? (
          <ExpertPageFilter
            onTechChange={setRelatedTech}
            onHIndexesChange={setHIndexes}
            onFilterChange={setApplyFilter}
            onOrgChange={setOrg}
            hIndexes={hIndexes}
            onTriggerFilter={() => {
              setTriggerFilter(!handleTriggerFilter);
            }}
            onSearch={handleSearch}
          />
        ) : (
          <ThemeProvider theme={theme}>
            <Collapse in={isOpenFilter} orientation="horizontal">
              <ExpertPageFilter
                onTechChange={setRelatedTech}
                onHIndexesChange={setHIndexes}
                onFilterChange={setApplyFilter}
                onOrgChange={setOrg}
                hIndexes={hIndexes}
                onTriggerFilter={() => {
                  setTriggerFilter(!handleTriggerFilter);
                }}
                onSearch={handleSearch}
              />
            </Collapse>
          </ThemeProvider>
        )}

        <ExpertDetail />
      </div>
    </>
  );
};

export default ExpertDetailPage;
