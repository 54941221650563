import '../../../pages/expert/ExpertPage.scss';
import '../../expert/ExpertDetail/ExpertDetail.css';
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Pagination from '@mui/material/Pagination';

export function SimilarList(props) {
  return props.similarExpert?.map((item) => (
    <Grid item xs={6} sm={4} md={3}   key={item.id}>
      <div className="similar-expert-item text-center">
        <div className="ava">
          <a href={'/expert/' + item._id}>
            <img
              onError={(e) => {
                e.currentTarget.src = '/images/avatar/default.jpg';
              }}
              src={
                item.avatar_url[0] === '/'
                  ? 'https://scholar.google.com' + item.avatar_url
                  : item.avatar_url
              }
            />
          </a>
        </div>
        <div className="expert-detail-info">
          <span className="name mb-4">
            <a href={'/expert/' + item._id}>{item.name}</a>
          </span>
          <p className="organisation text-capitalize mb-4">
            {item.organisation_name ? item.organisation_name : 'No data'}
          </p>
          {item.h_index && (
            <p className="h-index mb-4 text-capitalize">
              h-index: {item.h_index}
            </p>
          )}
          {item.citations && (
            <p className="h-index mb-4 text-capitalize">
              Citations: {item.citations}
            </p>
          )}
          {item.i10_index && (
            <p className="h-index mb-4 text-capitalize">
              I10_index: {item.i10_index}
            </p>
          )}
        </div>
      </div>
    </Grid>
  ));
}

const RelatedExperts = ({ similarExpert }) => {
  const [sortBy, setSortBy] = useState('h_index');
  const [page, setPage] = useState(1);
  const [experts, setExperts] = useState([]);

  useEffect(() => {
    let expertSort = similarExpert.sort(compare);
    const experts = expertSort.slice((page - 1) * 12, page * 12);
    setExperts(experts);
  }, [page, similarExpert, sortBy]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const compare = (a, b) => {
    if (sortBy === 'citations') return a.citations > b.citations ? -1 : 1;
    else if (sortBy === 'h_index') return a.h_index > b.h_index ? -1 : 1;
    else return a.i10_index > b.i10_index ? -1 : 1;
  };

  return (
    <div className="similar-expert d-flex flex-column mt-4">
      <div className="d-flex flex-row flex-grow-1 justify-content-between align-items-start mb-3">
        <span className="similar-expert-title">RELATED EXPERTS</span>
        {/* <div className="similar-expert-sort d-flex flex-row align-items-center">
          <h5>Sort by</h5>
          <select
            className="border-0 form-select"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="citations" style={{ fontSize: '13px' }}>
              Citations
            </option>
            <option value="i10_index" style={{ fontSize: '13px' }}>
              I10_index
            </option>
            <option value="h_index" style={{ fontSize: '13px' }}>
              H_index
            </option>
          </select>
          <button
            className="btn btn-light d-flex align-items-center border-0 p-0"
            title="Sort"
            disabled
          >
            <span className="material-icons">unfold_more</span>
          </button>
        </div> */}
      </div>
      <div className="related-experts">
        <Grid container rowSpacing={2} columnSpacing={8}>
          <SimilarList similarExpert={experts} sortBy={sortBy} />
        </Grid>
      </div>
      <div className="mt-5 text-center">
        <Pagination
          count={Math.ceil(similarExpert.length / 12)}
          page={page}
          onChange={handleChange}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default RelatedExperts;
