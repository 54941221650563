import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import './Footer.css';

const CustomLink = ({ children, to, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li className="nav-item" style={{ padding: '12px 25px' }}>
      <Link
        className={`nav-link d-flex justify-content-center`}
        to={to}
        {...props}
      >
        {children}
      </Link>
    </li>
  );
};

export default function Footer() {
  return (
    <footer>
      <div className="container" style={{ height: '100%', padding: '0' }}>
        <div className="d-flex flex-row justify-content-between align-items-center align-self-stretch py-2 footer-container">
          <a className="footer-logo" href="/">
            <img
              style={{ width: '211.44px', height: '48px' }}
              className="logo"
              src="/images/logos/Union.svg"
            />
          </a>
          <nav
            className="navbar navbar-expand-lg footer-nav"
            style={{ width: '449px' }}
          >
            <div className="container-fluid d-flex justify-content-center">
              <ul className="navbar-nav mb-2 mb-lg-0">
                <CustomLink to="/">Home</CustomLink>
                <CustomLink to="/organisation">Organisation</CustomLink>
                <CustomLink to="/expert">Expert</CustomLink>
                <CustomLink to="/technology">Technology</CustomLink>
                {/* <CustomLink to="/firm">Firm</CustomLink> */}
                {/* <CustomLink to="/project">Product & Solution</CustomLink>
                <CustomLink to="/association">Association</CustomLink> */}
              </ul>
            </div>
          </nav>
          <div className="d-flex justify-content-between">
            <img className="icon" src="/images/icons/facebook.svg" href="/" />
            <img className="icon" src="/images/icons/youtube.svg" href="/" />
            <img className="icon" src="/images/icons/instagram.svg" href="/" />
            <img className="icon" src="/images/icons/twitter.svg" href="/" />
            <img className="icon" src="/images/icons/linkedin.svg" href="/" />
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-center footer-border-top"
          style={{ height: '45px', color: 'white' }}
        >
          <label className="footer-label">
            &#169; 2022 Innovation Landscape. All Rights Reserved
          </label>
        </div>
      </div>
    </footer>
  );
}
