import { httpService } from '../services';
import { API } from '../endPoint';

const getTopOrganizations = async (count) => {
  let url = `${API.GET_TOP_ORGANIZATIONS}tok_k=${count}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getOrganizationById = async (id) => {
  let url = `${API.GET_ORGANIZATIONS_BY_ID}org_id=${id}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getSearchOrganizations = async ({
  name,
  size,
  page,
  isApplyFilter,
  sortBy,
  sortByDesc,
  expertSizes,
  relatedTechs,
  countries,
}) => {
  let url = API.GET_SEARCH_ORGANIZATIONS;

  if (sortBy) {
    const sortDir = sortByDesc ? 'desc' : 'asc';
    url += `sort_by=${encodeURIComponent(`${sortBy}`)}-${sortDir}&`;
  }

  if (name) {
    url += `name=${encodeURIComponent(`${name}`)}&`;
  }
  if (size) {
    url += `size=${encodeURIComponent(`${size}`)}&`;
  }
  if (page) {
    url += `page=${encodeURIComponent(`${page}`)}&`;
  }

  if (isApplyFilter) {
    if (expertSizes && expertSizes.length > 0) {
      expertSizes.forEach((item) => {
        url += `list_filter_related=${item}&`;
      });
    }
    if (relatedTechs && relatedTechs.length > 0) {
      relatedTechs.forEach((item) => {
        url += `list_tech_kw=${encodeURIComponent(item)}&`;
      });
    }

    if (countries && countries.length > 0) {
      countries.forEach((item) => {
          url += `countries_name=${item}&`;
      });
    }
  }

  url = url.replace(/[?&]$/, '');

  const response = await httpService.post(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getRelatedExpertsOrg = async (id) => {
  let url = `${API.GET_ORG_RELATED_EXPERTS}org_id=${id}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response.data;
};

const getListPaperOrg = async (id_org) => {
  let url = API.GET_LIST_PAPERS;
  if (id_org) url += `?id_org=${id_org}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response.data;
};

const getProminentExperts = async (org_id, limit = 10) => {
  let url = API.GET_PROMINENT_EXPERTS;
  if (org_id) url += `?id_org=${org_id}`;
  if (limit) url += `&limit=${limit}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response.data;
};

export {
  getTopOrganizations,
  getOrganizationById,
  getSearchOrganizations,
  getRelatedExpertsOrg,
  getListPaperOrg,
  getProminentExperts,
};
